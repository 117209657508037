import React from 'react';

export default function Cell(props) {
  const startEditing = () => {
    props.gridProps.api.startEditingCell({
      rowIndex: props.gridProps.rowIndex,
      rowPinned: props.gridProps.node.isRowPinned() ? 'top' : null,
      colKey: props.gridProps.column.getColId(),
    });
  };

  return (
    <div className={props.wrapperClassName}>
      {props.children}
      <a className="grid-cell-icon" onClick={startEditing}>
        <i className={`position-right pull-right ${props.iconClassName}`}></i>
      </a>
    </div>
  );
}