import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove
} from 'react-sortable-hoc';

import guid from './shared/guid';
import Column from './classificationFields/Column';

const DragHandle = SortableHandle(() => {
  return (
    <div className="col-xs-1 col-drag-handle">
      <i className="icon-three-bars text-muted pt-10 cursor-move " title="Reorder"></i>
    </div>
  );
})

const SortableItem = SortableElement(({idx,
                                       column,
                                       baseInputName,
                                       availableColumns,
                                       handleNameChange,
                                       handleHeaderChange,
                                       onRemove,
                                       showRemoveIcon}) => {
  return (
    <div className="row mb-10">
      <DragHandle />
      <Column
        index={idx}
        column={column}
        baseInputName={baseInputName}
        availableColumns={availableColumns}
        handleNameChange={handleNameChange}
        handleHeaderChange={handleHeaderChange}
        onRemove={onRemove}
        showRemoveIcon={showRemoveIcon}
      />
    </div>
  )
})

const SortableList = SortableContainer(({columns,
                                         baseInputName,
                                         availableColumns,
                                         handleNameChange,
                                         handleHeaderChange,
                                         onRemove,
                                         showRemoveIcon}) => {
  return (
    <div>
      {columns.map((column, index) =>
        <SortableItem
          key={column.key}
          index={index}
          idx={index}
          column={column}
          baseInputName={baseInputName}
          availableColumns={availableColumns}
          handleNameChange={handleNameChange}
          handleHeaderChange={handleHeaderChange}
          onRemove={onRemove}
          showRemoveIcon={showRemoveIcon}
        />
      )}
    </div>
  );
})

class ClassificationFields extends React.Component {
  static propTypes = {
    classificationName: PropTypes.string.isRequired,
    showSchemaField: PropTypes.bool.isRequired,
    initialKeyColumns: PropTypes.array,
    initialDataColumns: PropTypes.array,
    availableColumns: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      availableColumns: this.processInitialColumns(this.props.availableColumns),
      keyColumns: this.processInitialColumns(this.props.initialKeyColumns),
      dataColumns: this.processInitialColumns(this.props.initialDataColumns)
    }
  }

  processInitialColumns = (columns) => {
    let self = this;

    for (let column of columns) {
      column.id = self.columnId(column);
      column.key = column.id;
    }

    if (columns.length < 1) {
      columns.push(this.newColumn());
    }

    return columns;
  }

  columnId = (column) => {
    return column.columnType + "-" + column.name;
  }

  render () {
    let self = this;

    let schemaNode;

    if (this.props.showSchemaField) {
      schemaNode = (
        <div className='form-group'>
          <label className="control-label col-xs-2">Data Set Schema (Optional)</label>
          <div className="col-xs-1 col-drag-handle"></div>
          <div className='col-xs-6'>
            <input
              type="text"
              defaultValue=""
              onChange={this.handleSchemaChange}
              className='form-control'
            />
            <span className="help-block text-muted">
              This is the CSV header after you click 'Get Schema' for a given Data Set in Google Analytics Data Import section.
            </span>
          </div>
        </div>
      )
    }

    return (
      <div>
        {schemaNode}

        <div className='form-group'>
          <div className="col-xs-10 col-xs-offset-2">
            <div className="row">
              <div className="col-xs-1 col-drag-handle"></div>
              <div className="col-xs-10">
                <span className='text-semibold'>
                  Specify the mapping between UTM/Custom parameters/Info fields and column headings
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className='form-group'>
          <label className="control-label col-xs-2">
            <span>
              Key Column
              <a className="tool-tip"
                 data-toggle="tooltip"
                 data-placement="top"
                 data-trigger="hover"
                 title="Often utm_id or cid">
                <i className="icon-info22 text-muted text-size-small position-right"></i>
              </a>
            </span>
          </label>

          <div className="col-xs-10">
            {this.state.keyColumns.map((column, index) =>
              <div className="row mb-10" key={column.key}>
                <div className="col-xs-1 col-drag-handle"></div>
                <Column
                  index={index}
                  column={column}
                  baseInputName={self.props.classificationName + "[key_columns]"}
                  availableColumns={self.props.availableColumns}
                  handleNameChange={self.handleKeyNameChange}
                  handleHeaderChange={self.handleKeyHeaderChange}
                  onRemove={self.handleKeyColumnRemove}
                  showRemoveIcon={self.showRemoveIcon(self.state.keyColumns)}
                />
              </div>
            )}
          </div>
        </div>

        <div className='form-group'>
          <label className="control-label col-xs-2">
            <span>
              Data Columns
            </span>
          </label>

          <div className="col-xs-10">
            <SortableList
              columns={this.state.dataColumns}
              onSortEnd={this.onSortEnd}
              baseInputName={this.props.classificationName + "[data_columns]"}
              availableColumns={this.state.availableColumns}
              handleNameChange={this.handleDataNameChange}
              handleHeaderChange={this.handleDataHeaderChange}
              onRemove={this.handleDataColumnRemove}
              showRemoveIcon={this.showRemoveIcon(this.state.dataColumns)}
              useDragHandle={true}
              lockAxis="y"
            />

            <div className="row">
              <div className="col-xs-1 col-drag-handle"></div>
              <div className="col-xs-11">
                <a onClick={this.handleDataAddColumn}>
                  <i className="icon-plus22 position-left"></i>
                  Add column
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  onSortEnd = ({oldIndex, newIndex}) => {
     this.setState({ dataColumns: arrayMove(this.state.dataColumns, oldIndex, newIndex)});
  }

  handleSchemaChange = (event) => {
    self = this;

    let detectedHeaders = event.target.value.split(",");
    let keyHeader = detectedHeaders.shift();

    let newKeyColumns = [];
    newKeyColumns.push(this.columnForHeader(keyHeader));

    let newDataColumns = [];
    for (let header of detectedHeaders) {
      newDataColumns.push(self.columnForHeader(header));
    }

    this.setState({keyColumns: newKeyColumns, dataColumns: newDataColumns});
  }

  columnForHeader = (header) => {
    let newColumn = self.newColumn();
    newColumn.header = header;

    let validColumn = self.props.availableColumns.find(column => column.gaName === header);

    if (validColumn) {
      newColumn.id = validColumn.id
      newColumn.columnType = validColumn.columnType
      newColumn.name = validColumn.name
    }

    return newColumn;
  }

  handleNameChange = (index, value, columns, listName) => {
    let validColumn = this.state.availableColumns.find(column => column.id === value);

    if (validColumn) {
      let newDataColumns = update(
        columns, {
          [index]: {
            id:   {$set: validColumn.id},
            columnType: {$set: validColumn.columnType},
            name: {$set: validColumn.name}
          }
        }
      );

      this.setState({[listName]: newDataColumns});
    }
  }

  handleKeyNameChange = (index, value) => {
    this.handleNameChange(index, value, this.state.keyColumns, "keyColumns");
  }

  handleDataNameChange = (index, value) => {
    this.handleNameChange(index, value, this.state.dataColumns, "dataColumns");
  }

  handleKeyHeaderChange = (index, value) => {
    var newColumns = update(this.state.keyColumns, { [index]: { header: {$set: value} }});
    this.setState({keyColumns: newColumns});
  }

  handleDataHeaderChange = (index, value) => {
    var newColumns = update(this.state.dataColumns, { [index]: { header: {$set: value} }});
    this.setState({dataColumns: newColumns});
  }

  handleKeyColumnRemove = (index) => {
    let newColumns = update(this.state.keyColumns, {$splice: [[index, 1]]})
    this.setState({ keyColumns: newColumns })
  }

  handleDataColumnRemove = (index) => {
    let newColumns = update(this.state.dataColumns, {$splice: [[index, 1]]})
    this.setState({ dataColumns: newColumns })
  }

  handleKeyAddColumn = () => {
    let newColumns = update(
      this.state.keyColumns, {$push: [this.newColumn()]}
    )

    this.setState({ keyColumns: newColumns })
  }

  handleDataAddColumn = () => {
    let newColumns = update(
      this.state.dataColumns, {$push: [this.newColumn()]}
    )

    this.setState({ dataColumns: newColumns })
  }

  showRemoveIcon = (list) => {
    return list.length > 1
  }

  newColumn = () => {
    return { key: guid(), header: "", columnType: "", name: ""};
  }
};

export default ClassificationFields;
