import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

class TrackingParametersFields extends React.Component {
  static propTypes = {
    initialParams: PropTypes.array.isRequired,
    availableParams: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      selectedParams: this.props.initialParams,
    }
  }

  render() {
    let exampleNode;

    if (this.state.selectedParams.length > 0) {
      let exampleParams = this.state.selectedParams.map(function (param, index) {
        return param.value + "=value";
      });

      exampleNode = "Example: " + "http://www.example.com/?" + exampleParams.join("&");
    } else {
      exampleNode = "All UTM and custom parameters will be used to build your tracking URLs."
    }

    let selectedParamNodes = this.state.selectedParams.map(function (param, index) {
      return (
        <input
          key={param.value}
          type="hidden"
          name="project[tracking_parameters][]"
          value={param.value}
        />
      );
    });

    if (selectedParamNodes.length < 1) {
      selectedParamNodes = <input
        type="hidden"
        name="project[tracking_parameters]"
        value="default"
      />
    }

    return (
      <div>
        <div className='text-muted pb-10'>
          {exampleNode}
        </div>

        <Select
          options={this.props.availableParams}
          value={this.state.selectedParams}
          name=""
          onChange={this.handleParamValuesChange}
          placeholder="Select UTM/custom parameters in the required order"
          closeMenuOnSelect={false}
          disabled={this.props.disabled}
          className='tracking-parameters-container'
          classNamePrefix='tracking-parameters'
          isMulti
        />
        {selectedParamNodes}
      </div>
    )
  }

  handleParamValuesChange = (selectedParams) => {
    this.setState({ selectedParams: selectedParams });
  }
};

export default TrackingParametersFields;
