import React from 'react';
import PropTypes from 'prop-types';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove
} from 'react-sortable-hoc';

import Parameter from './sortingParameters/Parameter';

const DragHandle = SortableHandle(() => {
  return (
    <div className="col-xs-1 col-drag-handle">
      <i className="icon-three-bars text-muted cursor-move " title="Reorder"></i>
    </div>
  );
})

const SortableItem = SortableElement(({idx,
  column,
  baseInputName}) => {
    return (
      <div className="row pb-10 pt-10">
      <DragHandle />
      <Parameter
      index={idx}
      column={column}
      baseInputName={baseInputName}
      />
      </div>
    )
  })


const SortableList = SortableContainer(({columns,
  baseInputName}) => {
    return (
      <div>
      {columns.map((column, index) =>
        <SortableItem
        key={column.key}
        index={index}
        idx={index}
        column={column}
        baseInputName={baseInputName}
        />
      )}
      </div>
    );
  })

class SortingParameters extends React.Component {
  static propTypes = {
    sorting_parameters: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      sortingParameters: this.processInitialColumns(this.props.sorting_parameters)
    }
  }

  processInitialColumns = (columns) => {
    let self = this;

    for (let column of columns) {
      column.id = self.columnId(column);
      column.key = column.id;
    }

    return columns;
  }

  columnId = (column) => {
    return column.type + "-" + column.name;
  }

  render () {
    let self = this;

    let parameterNode;

    parameterNode = <div>
      <div className="row mb-10">
        <div className="col-xs-1 col-drag-handle"></div>
        <div className="col-xs-5">
          <div className="text-semibold">Parameters Order</div>
        </div>
        <div className="col-xs-1"></div>
      </div>

      <SortableList
        columns={this.state.sortingParameters}
        onSortEnd={this.onSortEnd}
        baseInputName={"project[sorting_parameters]"}
        useDragHandle={true}
        lockAxis="y"
      />
    </div>

    return (
      <div>
        <div className="col-xs-12">
          {parameterNode}
        </div>
      </div>
    )
  }

  onSortEnd = ({oldIndex, newIndex}) => {
     this.setState({ sortingParameters: arrayMove(this.state.sortingParameters, oldIndex, newIndex)});
  }
};

export default SortingParameters;
