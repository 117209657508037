import React from 'react';
import PropTypes from 'prop-types';
import FieldLabel from './FieldLabel';
import Description from './Description';

export default function ConstantField({ field, showLabel }) {
  return (
    <div>
      <FieldLabel field={field} showLabel={showLabel} />
      <input className="form-control" type="text" value={field.constant_value} disabled />
      <Description field={field} />
    </div>
  )
}

ConstantField.propTypes = {
  field: PropTypes.object.isRequired,
  showLabel: PropTypes.bool.isRequired
}