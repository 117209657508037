import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

export default function Field({ field, index, populateInputs, error = false, showFieldEditLink = false }) {
  const parentFieldNode = () => {
    if (field.parent_option_field_name) {
      return (
        <span>When value of
          <span className="text-semibold"> {field.parent_option_field_name} </span>
          is
          <span className="text-semibold"> {field.parent_option_field_value} </span>
        </span>
      )
    } else {
      return <span className="text-muted">None</span>;
    }
  };

  const parameterFormats = () => {
    const nodes = field.parameter_formats.map((parameterFormat) => {
      return (<li key={parameterFormat.id}>{parameterFormat.name}</li>)
    })

    if (nodes.length > 0) {
      return (
        <>
          <ul className="list-inline list-inline-separate">
            {nodes}
          </ul>
        </>
      )
    } else {
      return (<span className="text-muted">Unused</span>)
    }
  }

  const inputNodes = () => {
    if (populateInputs) {
      return (
        <>
          <input type={"hidden"} name={"parameter_format[elements_attributes][][elementable_id]"} value={field.id} />
          <input type={"hidden"} name={"parameter_format[elements_attributes][][position]"} value={index + 1} />
        </>
      )
    }
  }

  return (
    <Draggable draggableId={field.id.toString()} index={index}>
      {(provided) => (
        <li className={`field-card panel mb-10 border-${error ? 'danger' : 'default'}`} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          {inputNodes()}
          <div className="panel-body">
            <div className="row">
              <div className="col-sm-6">
                <h6 className="text-semibold no-margin-top">
                  {showFieldEditLink
                    ? <a href={field.edit_path} title='Edit'>{field.name}</a>
                    : field.name
                  }
                </h6>
                <span>
                  {field.description}
                </span>
              </div>
              <div className="col-sm-6">
                <h6 className="text-right no-margin-top">
                  <span className={`label label-flat border-${field.color} text-${field.color}`}>{field.type_name}</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="panel-footer panel-footer-condensed">
            <div className="heading-elements">
              <span className="heading-text">Used in:</span>
              <span className="heading-text ml-10">
                {parameterFormats()}
              </span>
              <span className="heading-text pull-right">
                <span className="">Rules:</span>
                <span className="text-size-small">
                  &nbsp;
                  {parentFieldNode()}
                </span>
              </span>
            </div>
          </div>
        </li>
      )
      }
    </Draggable >
  );
}

Field.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    type_name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    parent_option_field_name: PropTypes.string,
    parent_option_field_value: PropTypes.string,
    parameter_formats: PropTypes.arrayOf(PropTypes.any).isRequired,
    showFieldEditLink: PropTypes.bool
  }).isRequired,
  index: PropTypes.number.isRequired,
  populateInputs: PropTypes.bool.isRequired,
  error: PropTypes.bool
};
