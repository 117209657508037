import React from 'react';
import PropTypes from 'prop-types'

export default class CsrfToken extends React.PureComponent {
  static propTypes = {
   csrf: PropTypes.objectOf(PropTypes.string).isRequired
  }

  render () {
    return (
      <input type="hidden" name={this.props.csrf.param} value={this.props.csrf.token} />
    )
  }
}
