class TextInputValidator {
  constructor(maxLength, allowedCharacters, prohibitedCharacters) {
    this.maxLength = maxLength;
    this.allowedCharacters = allowedCharacters;
    this.prohibitedCharacters = prohibitedCharacters;
  }

  validate(value) {
    let newValue = value;

    if (this.maxLength > 0 && newValue.length > this.maxLength) {
      newValue = newValue.substring(0, this.maxLength);
    }

    if (this.prohibitedCharacters && this.prohibitedCharacters.length > 0) {
      let current_characters = newValue.split("");

      for (let char of current_characters) {
        if (this.prohibitedCharacters.includes(char)) {
          newValue = newValue.replace(char, "");
        }
      }
    }

    if (this.allowedCharacters && this.allowedCharacters.length > 0) {
      let current_characters = newValue.split("");

      for (let char of current_characters) {
        if (!this.allowedCharacters.includes(char)) {
          newValue = newValue.replace(char, "");
        }
      }
    }

    return newValue;
  }

  isValid(value) {
    return value === this.validate(value);
  }
};

export default TextInputValidator;

