export const multiLineTextToArray = (text) => {
  let arrayValues = text.split('\n');
  let trimmedValues = arrayValues.map(val => val.trim());
  let stringValues = trimmedValues.filter(String);
  return [...new Set(stringValues)]; // Return unique values
}

export const longestString = (array) => {
  let maxValue = "";

  for (let value of array) {
    if (value.length > maxValue.length) {
      maxValue = value;
    }
  }

  return maxValue;
}

export const arrayToText = (value) => {
  const coercedArray = [].concat(value);
  return coercedArray.join("\n")
}
