import React from 'react';
import PropTypes from 'prop-types';

export default function AddPermission({ projects, addPermission }) {
  return (
    <div className="btn-group">
      <button aria-expanded="true" className="btn btn-default dropdown-toggle" data-toggle="dropdown" type="button">
        Add Project Permission
            <span className="caret"></span>
      </button>
      <ul className="dropdown-menu dropdown-menu-left">
        {projects.map((project) => (
          <li key={project.id}>
            <a onClick={() => addPermission(project.id)}>{project.name}</a>
          </li>
        ))}
      </ul>
    </div>
  );
}

AddPermission.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.any).isRequired,
  addPermission: PropTypes.func.isRequired
};
