import React from 'react';
import PropTypes from 'prop-types';
import FieldSet from './conventionFields/FieldSet';
import { useFields } from './conventionFields/useFields';
import FormatResult from './conventionFields/FormatResult';
import SimplePortal from './shared/SimplePortal';

import {
  calculatedFormatValue,
  areAllRequiredFieldsFilled,
  findFieldForFieldId
} from './conventionFields/fieldHelpers';

export default function FieldModeConvention({ initial_fields, target_formats, form_object_name, prohibited_characters }) {
  const { fields, updateFieldValue, updateMultipleFieldValues } = useFields(initial_fields);

  // Constant fields are NOT rendered, but still used. Not using visible to avoid confusion.
  // TODO: Replace field.visible with field.active to make it consistent across all components
  const activeFields = () => {
    return fields.filter(field => field.visible);
  }

  // TODO: Eventually the ids will be strings and this will need to be updated
  // The fields should be returned in the order of fieldIds to build the correct value
  const formatFields = (fieldIds) => {
    let result = []
    for (const fieldId of fieldIds) {
      const field = findFieldForFieldId(fieldId, activeFields());
      if (field) {
        result.push(field)
      }
    }
    return result;
  }

  return (
    <div>
      <input // Submit all the active fields. In case of validation error, it's used to repopulate the form (and for direct saving in the future)
        type="hidden"
        name={form_object_name + "[link_fields_map]"}
        value={JSON.stringify(calculatedFormatValue(activeFields(), "").fieldsMap)}
      />
      {target_formats.map(format => (
        // Don't use format.id for key since it could be used multiple times in a convention
        <SimplePortal key={format.result_dom_id} domId={format.result_dom_id}>
          <FormatResult
            inputName={format.input_name}
            formatId={format.id}
            result={calculatedFormatValue(formatFields(format.field_ids), format.separator)}
          />
        </SimplePortal>
      ))
      }
      <div className="panel panel-white" >
        <div className="panel-heading">
          <span className="text-semibold panel-title">Convention fields</span>
          {!areAllRequiredFieldsFilled(activeFields()) &&
            <div>
              <span className="help-block text-warning no-margin-bottom">
                All required values should be filled
              </span>
              <input readOnly type="hidden" data-input-error='error' />
            </div>
          }
        </div>
        <div className="panel-body">
          <FieldSet
            fields={activeFields()}
            showLabel={true}
            updateFieldValue={updateFieldValue}
            updateMultipleFieldValues={updateMultipleFieldValues}
            prohibitedCharacters={prohibited_characters}
            allowMultipleFieldValues={false}
            showConstantField={false}
          />
        </div>
      </div>
    </div >
  )
}

FieldModeConvention.propTypes = {
  initial_fields: PropTypes.array.isRequired,
  target_formats: PropTypes.array.isRequired,
  form_object_name: PropTypes.string.isRequired,
  prohibited_characters: PropTypes.array.isRequired,
}