import React from 'react';
import PropTypes from 'prop-types';

// Use this in ParameterFormat later once stable
export default function FormatResult({ inputName, formatId, result, hidden = false }) {
  const inputType = hidden ? 'hidden' : 'text';

  return (
    <div>
      <input
        className="form-control"
        readOnly
        type={inputType}
        name={inputName + "[tag]"}
        value={result.value}
      />
      <input
        type="hidden"
        name={inputName + "[parameter_format_id]"}
        value={formatId}
      />
      <input
        type="hidden"
        name={inputName + "[fields_map]"}
        value={JSON.stringify(result.fieldsMap)}
      />
    </div>
  )
}

FormatResult.propTypes = {
  inputName: PropTypes.string.isRequired,
  formatId: PropTypes.string.isRequired,
  result: PropTypes.object.isRequired
}