import React from 'react';
import PropTypes from 'prop-types'
import Select2 from 'react-select2-wrapper';
import ParameterSelect from '../multipleTagUrlBuilder/ParameterSelect';
import LabelSelect from '../multipleTagUrlBuilder/LabelSelect';

class ParametersInput extends React.PureComponent {
  static propTypes = {
    index: PropTypes.number.isRequired,
    campaigns: PropTypes.array,
    media: PropTypes.array,
    sources: PropTypes.array,
    contents: PropTypes.array,
    terms: PropTypes.array,
    availableCustomParameters: PropTypes.array,
    availableInfoFields: PropTypes.array,
    availableLabels: PropTypes.array,
    presets: PropTypes.array,
    utmCampaign: PropTypes.string,
    utmMedium: PropTypes.string,
    utmSource: PropTypes.string,
    utmContent: PropTypes.string,
    utmTerm: PropTypes.string,
    utmCampaignRequired: PropTypes.bool.isRequired,
    utmMediumRequired: PropTypes.bool.isRequired,
    utmSourceRequired: PropTypes.bool.isRequired,
    utmContentRequired: PropTypes.bool.isRequired,
    utmTermRequired: PropTypes.bool.isRequired,
    customParameters: PropTypes.object,
    infoFields: PropTypes.array,
    labelNames: PropTypes.array,
    description: PropTypes.string,
    preset: PropTypes.object,
    handleParameterValueChange: PropTypes.func.isRequired,
    handleLabelNamesChange: PropTypes.func.isRequired,
    handleDescriptionChange: PropTypes.func.isRequired,
    handlePresetChange: PropTypes.func.isRequired,
    showRemoveIcon: PropTypes.bool.isRequired,
    handleRemove: PropTypes.func.isRequired,
    handleClone: PropTypes.func.isRequired,
    allowCreate: PropTypes.bool.isRequired,
    highPerformanceMode: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props)

    this.handleParameterValueChange = this.handleParameterValueChange.bind(this);
    this.handleCustomParameterValueChange = this.handleCustomParameterValueChange.bind(this);
    this.handleInfoFieldValueChange = this.handleInfoFieldValueChange.bind(this);
    this.handleLabelNamesChange = this.handleLabelNamesChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleClone = this.handleClone.bind(this);
  }

  render() {

    var self = this;

    var removeIconNode;

    // TODO: Move this to a function to prevent creating new references to presetsSelectData
    // on each render
    var presetsSelectData = this.props.presets.map(function (preset, index) {
      return { id: preset.id, text: preset.name, title: preset.name };
    });

    var presetId;
    if (this.props.preset) {
      presetId = this.props.preset.id
    }

    var customParametersNodes = this.props.availableCustomParameters.map(function (customParameter, index) {
      var name = "multiple_tag_url_builder[builder_attrs_list][][custom][" + customParameter.name + "][tag]";
      var value = self.props.customParameters[customParameter.name];

      return (
        <td key={customParameter.id}>
          <ParameterSelect
            parameterName={customParameter.name}
            parameterLabel={customParameter.name}
            isCustomParameter={true}
            isInfoField={false}
            value={value}
            list={customParameter.custom_parameter_values}
            required={customParameter.required}
            inputName={name}
            handleChange={self.handleCustomParameterValueChange}
            highPerformanceMode={self.props.highPerformanceMode}
            autoGenerated={customParameter.either_generated}
          />
        </td>
      )
    });

    let infoFieldsNodes = this.props.availableInfoFields.map(function (infoField, index) {
      var name = "multiple_tag_url_builder[builder_attrs_list][][info][" + infoField.name + "][tag]";
      var value = self.props.infoFields[infoField.name];

      return (
        <td key={infoField.id}>
          <ParameterSelect
            parameterName={infoField.name}
            parameterLabel={infoField.name}
            value={value}
            list={infoField.active_info_field_values}
            required={infoField.required}
            inputName={name}
            handleChange={self.handleInfoFieldValueChange}
            highPerformanceMode={self.props.highPerformanceMode}
            autoGenerated={infoField.either_generated}
          />
        </td>
      )
    });

    let cloneNode = (
      <a className="tool-tip text-primary pr-10"
        data-toggle="tooltip"
        data-placement="top"
        data-trigger="hover"
        title="Clone this row"
        tabIndex="-1"
        onClick={this.handleClone}>
        <i className="icon-copy4"></i>
      </a>
    )

    /* TODO: Feels like this should be in the parent, since it's
     * here only to include in as td element. But not sure how to
     * move it up since React only allows one html element in
     * `render`.
     */
    if (this.props.showRemoveIcon) {
      removeIconNode = (
        <a title="Remove"
          className="text-danger"
          tabIndex="-1"
          onClick={this.handleRemove}>
          <i className="icon-cross3" tabIndex="-1"></i>
        </a>
      )
    }

    var presetNode = <Select2
      value={presetId}
      data={presetsSelectData}
      onSelect={this.handlePresetSelect}
      onUnselect={this.handlePresetUnselect}
      options={
        {
          width: '100%',
          allowClear: true,
          placeholder: "Use a preset"
        }
      }
    />

    var campaignNode = <ParameterSelect
      parameterName={"utmCampaign"}
      parameterLabel={"Campaign"}
      isCustomParameter={false}
      isInfoField={false}
      value={this.props.utmCampaign}
      list={this.props.campaigns}
      required={this.props.utmCampaignRequired}
      inputName="multiple_tag_url_builder[builder_attrs_list][][utm][campaign][tag]"
      handleChange={self.handleParameterValueChange}
      highPerformanceMode={self.props.highPerformanceMode}
      autoGenerated={false}
    />

    var mediumNode = <ParameterSelect
      parameterName={"utmMedium"}
      parameterLabel={"Medium"}
      isCustomParameter={false}
      isInfoField={false}
      value={this.props.utmMedium}
      list={this.props.media}
      required={this.props.utmMediumRequired}
      inputName="multiple_tag_url_builder[builder_attrs_list][][utm][medium][tag]"
      handleChange={self.handleParameterValueChange}
      highPerformanceMode={self.props.highPerformanceMode}
      autoGenerated={false}
    />

    var sourceNode = <ParameterSelect
      parameterName={"utmSource"}
      parameterLabel={"Source"}
      isCustomParameter={false}
      isInfoField={false}
      value={this.props.utmSource}
      list={this.props.sources}
      required={this.props.utmSourceRequired}
      inputName="multiple_tag_url_builder[builder_attrs_list][][utm][source][tag]"
      handleChange={self.handleParameterValueChange}
      highPerformanceMode={self.props.highPerformanceMode}
      autoGenerated={false}
    />

    var contentNode = <ParameterSelect
      parameterName={"utmContent"}
      parameterLabel={"Content"}
      isCustomParameter={false}
      isInfoField={false}
      value={this.props.utmContent}
      list={this.props.contents}
      required={this.props.utmContentRequired}
      inputName="multiple_tag_url_builder[builder_attrs_list][][utm][content][tag]"
      handleChange={self.handleParameterValueChange}
      highPerformanceMode={self.props.highPerformanceMode}
      autoGenerated={false}
    />

    var termNode = <ParameterSelect
      parameterName={"utmTerm"}
      parameterLabel={"Term"}
      isCustomParameter={false}
      isInfoField={false}
      value={this.props.utmTerm}
      list={this.props.terms}
      required={this.props.utmTermRequired}
      inputName="multiple_tag_url_builder[builder_attrs_list][][utm][term][tag]"
      handleChange={self.handleParameterValueChange}
      highPerformanceMode={self.props.highPerformanceMode}
      autoGenerated={false}
    />

    let labelNode = <LabelSelect
      labelNames={this.props.labelNames}
      availableLabels={this.props.availableLabels}
      inputName="multiple_tag_url_builder[builder_attrs_list][][label_names][]"
      handleChange={self.handleLabelNamesChange}
    />


    var descriptionNode = <input
      value={this.props.description}
      type="text"
      name="multiple_tag_url_builder[builder_attrs_list][][description]"
      onChange={self.handleDescriptionChange}
      className="form-control"
    />

    let markup = <tr>
      <td className='preset'>
        {presetNode}
      </td>
      <td className='utm-campaign'>{campaignNode}</td>
      <td className='utm-medium'>{mediumNode}</td>
      <td className='utm-source'>{sourceNode}</td>
      <td className='utm-content'>{contentNode}</td>
      <td className='utm-term'>{termNode}</td>
      {customParametersNodes}
      {infoFieldsNodes}
      <td>{labelNode}</td>
      <td>{descriptionNode}</td>
      <td className="tags-remove">
        <ul className="icons-list">
          <li>{cloneNode}</li>
          <li>{removeIconNode}</li>
        </ul>
      </td>
    </tr>

    return markup;
  }

  handlePresetSelect = (event) => {
    this.props.handlePresetChange(this.props.index, event.target.value);
  }

  handlePresetUnselect = (event) => {
    this.props.handlePresetChange(this.props.index, "");
  }

  handleParameterValueChange = (parameterName, value) => {
    this.props.handleParameterValueChange(this.props.index, parameterName, value, false, false);
  }

  handleCustomParameterValueChange = (parameterName, value) => {
    this.props.handleParameterValueChange(this.props.index, parameterName, value, true, false);
  }

  handleInfoFieldValueChange = (parameterName, value) => {
    this.props.handleParameterValueChange(this.props.index, parameterName, value, false, true);
  }

  handleLabelNamesChange = (labelNames) => {
    this.props.handleLabelNamesChange(this.props.index, labelNames);
  }

  handleDescriptionChange = (event) => {
    this.props.handleDescriptionChange(this.props.index, event.target.value);
  }

  handleRemove = () => {
    this.props.handleRemove(this.props.index);
  }

  handleClone = () => {
    this.props.handleClone(this.props.index);
  }
};

export default ParametersInput;
