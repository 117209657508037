import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import Permission from './permissionsConfiguration/Permission'
import AddPermission from './permissionsConfiguration/AddPermission'
import guid from './shared/guid';

const ADD_PERMISSION = 'ADD_PERMISSION'
const UPDATE_PERMISSION = 'UPDATE_PERMISSION'
const DELETE_PERMISSION = 'DELETE_PERMISSION'

const permissionsReducer = (permissions, action) => {
  switch (action.type) {
    case ADD_PERMISSION:
      return [...permissions, action.payload]
    case UPDATE_PERMISSION:
      return permissions.map(permission => {
        if (permission.id !== action.payload.id) return permission
        return { ...permission, [action.payload.flagName]: action.payload.flagValue }
      })
    case DELETE_PERMISSION:
      return permissions.filter(permission => permission.id !== action.payload.id)
    default:
      throw new Error();
  }
}

export default function PermissionsConfiguration({ projects = [], initialPermissions = [], inputNamePrefix }) {
  const [permissions, dispatch] = useReducer(permissionsReducer, initialPermissions);

  // Handler functions
  const addPermission = (project_id) => {
    dispatch({
      type: ADD_PERMISSION,
      payload: {
        project_id,
        id: guid()
      }
    });
  };

  const updatePermission = (id, flagName, flagValue) => {
    dispatch({
      type: UPDATE_PERMISSION,
      payload: {
        id,
        flagName, // e.g. write_campaign
        flagValue // e.g. true
      }
    });
  };

  const deletePermission = (id) => {
    dispatch({
      type: DELETE_PERMISSION,
      payload: {
        id
      }
    });
  };

  const getProject = (projectId) => {
    return projects.find(project => project.id === projectId)
  }

  // Projects that are not in any permissions
  const availableProjectsForNewPermission = projects.filter((project) => !permissions.some((permission) => permission.project_id === project.id))

  return (
    <table className='table table-framed'>
      <thead>
        <tr>
          <th>Project Name</th>
          <th>
            <abbr className="tool-tip" data-original-title="Allow to create, edit or delete URLs">URLs</abbr>
          </th>
          <th>
            <abbr className="tool-tip" data-original-title="Allow to create, edit or delete UTM and Custom parameters">Params</abbr>
          </th>
          <th>
            <abbr className="tool-tip" data-original-title="Allow to create, edit or delete Presets, Preset Groups and Parameter Groups">Presets</abbr>
          </th>
          <th>
            <abbr className="tool-tip" data-original-title="Allow to modify project settings including conventions.">Settings</abbr>
          </th>
          <th>
            Actions
          </th>
        </tr>
      </thead>
      <tbody>
        {permissions.map((permission) => (
          <Permission
            key={permission.id}
            permission={permission}
            project={getProject(permission.project_id)}
            inputNamePrefix={inputNamePrefix}
            updatePermission={updatePermission}
            deletePermission={deletePermission}
          />
        ))}
        {permissions.length === 0 &&
          <tr>
            <td>
              <input type="hidden" name={inputNamePrefix} value="[]" />
            </td>
          </tr>
        }
        <tr>
          <td colSpan={6}>
            <AddPermission
              projects={availableProjectsForNewPermission}
              addPermission={addPermission}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

PermissionsConfiguration.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.any).isRequired,
  initialPermissions: PropTypes.arrayOf(PropTypes.any).isRequired,
  inputNamePrefix: PropTypes.string.isRequired
};
