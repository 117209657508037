import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle
} from "react";
import TextInputValidator from "../conventionFields/TextInputValidator";
import TextCounter from "../conventionFields/TextCounter";

import styled from 'styled-components';

const StyledInput = styled.input`
    width: 100%;
    height: 25px;
    padding-left: 4px;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    border: none;

    &:focus {
      outline: none;
    }
`;

export default forwardRef((props, ref) => {
  const [valueObject, setValueObject] = useState(props.value);
  const [editing, setEditing] = useState(true);
  const refInput = useRef(null);

  const width = props.column.getActualWidth() + 'px';
  const height = props.rowHeight + 'px';

  const textValidator = new TextInputValidator(props.textLimit, props.allowedCharacters, props.prohibitedCharacters);

  useEffect(() => {
    setTimeout(() => refInput.current.focus());
  }, []);

  // TODO: This is common with other editors
  useEffect(() => {
    if (!editing) {
      props.api.stopEditing();
    }
  }, [editing]);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return valueObject;
      },
    };
  });

  const onChangeHandler = (e) => {
    const value = textValidator.validate(e.target.value);
    setValueObject({ value: value });
  }

  const handleOnBlur = () => {
    setEditing(false);
  }

  return (
    <div className='input-group text-cell-container' style={{ width: width, height: height }} >
      <StyledInput type="text"
        ref={refInput}
        value={valueObject.value}
        onChange={onChangeHandler}
        onBlur={handleOnBlur}
      />
      <span className="input-group-addon no-border-radius p-5 no-border text-size-small">
        <TextCounter maxCount={props.textLimit} currentValue={valueObject.value} />
      </span>
    </div>
  );
});