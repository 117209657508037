import React from 'react';
import EditableCell from './EditableCell';

export default props => {
  const value = props.value && props.value.value; // props.value can be null when clearing the cell
  const textWidth = (props.column.getActualWidth() - 50) + 'px';

  const textStyle = {
    display: 'inline-block',
    maxWidth: textWidth,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }

  return (
    <EditableCell gridProps={props} wrapperClassName='grid-dropdown-cell-renderer' iconClassName={props.iconClassName}>
      <span style={textStyle}>{value}</span>
    </EditableCell>
  );
}
