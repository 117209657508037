import React, { memo } from 'react';

export default memo(props => {
  return (
    <div>
      <a className="tool-tip"
        data-toggle="tooltip"
        data-placement="top"
        data-trigger="hover"
        title="Specify values in this row and click Generate button to populate with all possible combinations.">
        <i className="icon-info22"></i>
      </a>
    </div>
  );
});
