export default class ExportQueryUpdater {
  constructor(exportLinkElementClass, exportItemCountClass) {
    this.exportLinkElementClass = exportLinkElementClass;
    this.exportItemCountClass = exportItemCountClass;
    this.setup();
  }

  setup() {
    let self = this;
    self.links = $(self.exportLinkElementClass);

    // Save the original href on each link
    // NOTE: This can potentially be done in the view, but this seems easy enough
    for (let link of self.links) {
      link.setAttribute('data-original-href', link.href);
    };
  }

  // NOTE: Make sure to bind this function with the parent object
  // to define 'this' in this context
  update(itemIds) {
    let self = this;

    for (let link of self.links) {
      let originalHref = link.getAttribute('data-original-href');
      let href = originalHref;

      // let originalExportCount = link.getAttribute('data-original-export-count');
      let exportCount = "";

      if (itemIds.length > 0) {
        let url = new URL(originalHref);
        let searchParams = new URLSearchParams(url.search);

        // Remove any existing search parameters. Iterate over the original since searchParams is being modified
        url.searchParams.forEach(function(value, key) {
          if (key.match(/q\[.*\]/)) {
            searchParams.delete(key);
          }
        });

        // Append all the item ids in the query parameter
        for (let itemId of itemIds) {
          searchParams.append('q[id_in][]', itemId);
        };

        url.search = searchParams.toString();
        href = url.toString();
        exportCount = "(" + itemIds.length + ")";
      }

      link.href = href;
      $(link).find(self.exportItemCountClass).text(exportCount);
    }
  }
};
