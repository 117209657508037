export default class Cartesian {
  constructor(arrays) {
    this.arrays = arrays;
  }

  // From http://stackoverflow.com/a/15310051
  // TODO: Perhaps move to https://stackoverflow.com/a/43053803
  // const cartesian = (...a) => a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())));

  values() {
    var self = this;
    var r = [], max = self.arrays.length - 1;
    function helper(arr, i) {
      for (var j = 0, l = self.arrays[i].length; j < l; j++) {
        var a = arr.slice(0); // clone arr
        a.push(self.arrays[i][j]);
        if (i == max) {
          r.push(a);
        } else {
          helper(a, i + 1);
        }
      }
    }
    helper([], 0);
    return r;
  }

  count() {
    var self = this;

    var total = 1;
    for (const item of self.arrays) {
      total *= item.length;
    }
    return total;
  }
};
