import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle
} from "react";
import TextInputValidator from "../conventionFields/TextInputValidator";
import TextCounter from "../conventionFields/TextCounter";
import styled from 'styled-components';

import { multiLineTextToArray, longestString, arrayToText } from "../shared/StringUtilities";

const StyledTextarea = styled.textarea`
    width: 100%;
    padding-left: 4px;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    border: none;

    &:focus {
      outline: none;
    }
`;

const arrayOfValues = (valueObjects) => {
  return valueObjects.map(item => item.value);
}

export default forwardRef((props, ref) => {
  const [valueObject, setValueObject] = useState(props.value);
  const textValues = arrayOfValues(props.value);
  const [rawText, setRawText] = useState(arrayToText(textValues));
  const [valid, setValid] = useState(true);
  const [editing, setEditing] = useState(true);
  const refInput = useRef(null);

  const textValidator = new TextInputValidator(props.textLimit, props.allowedCharacters, props.prohibitedCharacters);

  useEffect(() => {
    setTimeout(() => refInput.current.focus());
  }, []);

  // TODO: This is common with other editors
  useEffect(() => {
    if (!editing) {
      props.api.stopEditing();
    }
  }, [editing]);

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return valueObject;
      },

      // afterGuiAttached: () => {
      //   //   setValue(props.value)
      //   refInput.current.focus();
      // },
    };
  });

  const onChangeHandler = (e) => {
    let text = e.target.value;
    let textValues = multiLineTextToArray(text);
    let arrayOfObjects = [];

    let tempValid = true;

    for (const item of textValues) {
      const newValue = textValidator.validate(item)
      const newValueObject = { value: newValue }
      tempValid = tempValid && (newValue === item);
      arrayOfObjects = arrayOfObjects.concat(newValueObject);
    }

    setValid(tempValid);
    setValueObject(arrayOfObjects);
    setRawText(text);
  }


  const handleOnBlur = () => {
    setEditing(false);
  }

  const longestValue = longestString(arrayOfValues(valueObject))

  return (
    <div className='multi-text-cell-container' style={{ width: '300px' }} >
      <StyledTextarea
        rows={5}
        // cols={60}
        placeholder="Enter one value on each line"
        ref={refInput}
        value={rawText}
        onChange={onChangeHandler}
        onBlur={handleOnBlur}
      // style={{ width: "100%" }}
      />
      <div className="text-size-small" style={{ margin: '2px', padding: '2px' }}>
        {props.textLimit > 0 &&
          <span>
            For longest value:&nbsp;
            <TextCounter maxCount={props.textLimit} currentValue={longestValue} counterClassNames="text-semibold" />
          </span>
        }
        {!valid && <span className='text-danger text-semibold pull-right'>Invalid</span>}
      </div>
    </div>
  );
});