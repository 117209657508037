import React from 'react';

export default function Checkbox({ id, label, value, onChange, name }) {
  return (
    <div className="checkbox">
      <label>
        {!value &&
          <input type='hidden' name={name} value='0' />
        }
        <input
          id={id}
          type="checkbox"
          checked={value}
          value={value ? '1' : '0'}
          name={name}
          onChange={onChange} />
        {label}
      </label>
    </div>
  );
};