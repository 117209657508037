import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

class PresetGroupForm extends React.Component {
  static propTypes = {
    initialName: PropTypes.string.isRequired,
    initialNameErrors: PropTypes.array.isRequired,
    initialPresets: PropTypes.array.isRequired,
    allPresets: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props);

    var allPresets = this.props.allPresets || [];

    allPresets.map(function (preset) {
      preset.label = preset.name;
      preset.value = preset.name;
    });

    var selectedPresets = this.props.initialPresets || [];

    selectedPresets.map(function (preset) {
      preset.label = preset.name;
      preset.value = preset.name;
    });

    this.state = {
      name: this.props.initialName,
      selectedPresets: selectedPresets,
      allPresets: allPresets
    }
  }

  render() {
    var nameErrorNode, selectedPresetNodes;

    nameErrorNode = this.props.initialNameErrors.map(function (error, index) {
      return (
        <span key={index} className="text-error">{error}</span>
      );
    });

    selectedPresetNodes = this.state.selectedPresets.map(function (preset, index) {
      return (
        <input
          key={preset.id}
          type="hidden"
          name="preset_group[preset_ids][]"
          value={preset.id}
        />
      );
    });

    return (
      <div>
        <div className="form-group">
          <label className="control-label col-md-1" htmlFor="preset_group_name">Name</label>
          <div className="col-md-2">
            <input
              id="preset_group_name"
              type="text"
              className="form-control"
              name="preset_group[name]"
              required="required"
              value={this.state.name}
              onChange={this.handleNameChange}
            />
            {nameErrorNode}
          </div>
        </div>
        <div className="form-group preset-group">
          <label className="control-label col-md-1" htmlFor="preset_group_preset_ids">Presets</label>
          <div className="col-md-5">
            <Select
              // menuIsOpen={true}
              options={this.state.allPresets}
              value={this.state.selectedPresets}
              name=""
              onChange={this.handlePresetValuesChange}
              placeholder="Use a Preset"
              className='preset-group-container'
              classNamePrefix='preset-group'
              closeMenuOnSelect={false}
              isSearchable={true}
              isClearable={true}
              isMulti={true}
            />
            <span className="help-block">
              Select multiple presets to form a preset group
            </span>
          </div>
          {selectedPresetNodes}
        </div>
      </div>
    )
  }

  handleNameChange = (e) => {
    var name = e.target.value;
    this.setState({ name: name });
  }

  handlePresetValuesChange = (selectedPresets) => {
    this.setState({ selectedPresets: selectedPresets });
  }
}

export default PresetGroupForm;
