import { useMemo, useEffect, useReducer } from 'react';
import {
  fieldsReducer,
  UPDATE_FIELD_VALUE,
  UPDATE_MULTIPLE_FIELD_VALUES,
  REFRESH_FIELD_VISIBILITY,
  bulkUpdateFields
} from './fieldsReducer';

// NOTE: Populating using a separator is supported for UTM/links created before Aug/Sept 2020 (when fields_map was added)
export const useFields = (initialFields = [], initialValue = '', separatorPopulate = false, separator = '') => {
  // Populate using separator if needed (for backward compatibility)
  const preparedInitialFields = useMemo(() => {
    if (separatorPopulate) {
      const fieldValues = initialValue.split(separator);
      return bulkUpdateFields(initialFields, fieldValues);
    } else {
      return initialFields;
    }

  }, []);

  const [fields, dispatch] = useReducer(fieldsReducer, preparedInitialFields)

  const updateFieldValue = (id, value) => {
    dispatch({
      type: UPDATE_FIELD_VALUE,
      payload: { id, value }
    });
  };

  const updateMultipleFieldValues = (id, values) => {
    dispatch({
      type: UPDATE_MULTIPLE_FIELD_VALUES,
      payload: { id, values }
    });
  };

  const refreshFieldVisibilty = () => {
    dispatch({
      type: REFRESH_FIELD_VISIBILITY,
      payload: {}
    });
  };

  // NOTE: Run this once to set visibility based on initial data
  useEffect(() => {
    refreshFieldVisibilty();
  }, []); // Run only once on initial render.

  return { fields, updateFieldValue, updateMultipleFieldValues }
}