import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FieldSet from './conventionFields/FieldSet';
import { useFields } from './conventionFields/useFields';

import {
  calculatedFormatValue,
  calculatedMultipleFormatValues,
  areAllRequiredFieldsFilled
} from './conventionFields/fieldHelpers';

export default function ParameterFormat({
  initial_value,
  separator_populate,
  parameter_format,
  initial_fields,
  input_name,
  pf_id_name,
  fields_map_name,
  prohibited_characters,
  allow_multiple_field_values,
  max_character_limit
}) {

  const { fields, updateFieldValue, updateMultipleFieldValues } = useFields(initial_fields, initial_value, separator_populate, parameter_format.separator)

  useEffect(() => {
    new Clipboard('.copy-values');
  }, []); // Run only once on initial render.

  const multipleFields = fields.length > 1;

  const visibleFields = () => {
    return fields.filter(field => field.visible);
  }

  const calculatedParameterValue = () => {
    return calculatedFormatValue(visibleFields(), parameter_format.separator);
  }

  const calculatedMultipleParameterValues = () => {
    return calculatedMultipleFormatValues(visibleFields(), parameter_format.separator);
  }

  // Belongs with the parameter/fields?
  const checkInputError = () => {
    const inputError = !areAllRequiredFieldsFilled(visibleFields()) || inputCharacterLimitExceeded();
    return inputError ? 'error' : 'none';
  }

  // Belongs with the parameter
  const inputCharacterLimitExceeded = () => {
    return max_character_limit > 0 && (calculatedParameterValue().value.length > max_character_limit)
  }

  const fieldsNodes = (
    <FieldSet
      fields={visibleFields()}
      showLabel={multipleFields}
      updateFieldValue={updateFieldValue}
      updateMultipleFieldValues={updateMultipleFieldValues}
      prohibitedCharacters={prohibited_characters}
      allowMultipleFieldValues={allow_multiple_field_values}
    />
  );

  // Belongs with the fields
  let requiredHelpBlock;
  if (!areAllRequiredFieldsFilled(visibleFields())) {
    // Using text-warning because jQuery.validator interefers with text-danger span elements
    requiredHelpBlock = (
      <span className="help-block text-warning no-margin-bottom">
        All required values should be filled
      </span>
    );
  };

  // Belongs with the parameter
  let characterLimitHelpBlock;
  if (inputCharacterLimitExceeded()) {
    // Using text-warning because jQuery.validator interefers with text-danger span elements
    characterLimitHelpBlock = (
      <span className="help-block text-warning">
        It's too long with {calculatedParameterValue().value.length} characters (Maximum {max_character_limit})
      </span>
    );
  };

  // Belongs with the parameter
  let parameterInfoNodes = (
    <div>
      <input
        type="hidden"
        name={pf_id_name}
        value={parameter_format.id}
      />
      <input
        type="hidden"
        name={fields_map_name}
        value={JSON.stringify(calculatedParameterValue().fieldsMap)}
      />
    </div>
  )

  let multipleFieldsNode;

  if (allow_multiple_field_values) {
    // Belongs with the parameter
    const inputNodes = calculatedMultipleParameterValues().map(function (joinedValue, index) {
      return (
        <input
          key={index}
          data-input-error={checkInputError()}
          readOnly
          type="hidden"
          name={input_name}
          value={joinedValue}
        />
      );
    });

    let newLineValues = calculatedMultipleParameterValues().join("\n");

    // Belongs with the parameter
    let visibleMultiValueNode = (
      <div>
        <textarea
          className="form-control"
          rows={8}
          readOnly
          value={newLineValues}
        />
        <a data-clipboard-text={newLineValues} className="copy-values text-muted text-size-mini pt-10">
          <i className="icon-copy4 position-left"></i>
          Copy
        </a>
      </div>
    )

    multipleFieldsNode = (
      <div>
        {visibleMultiValueNode}
        {inputNodes}
      </div>
    )
  } else {
    // Belongs with the parameter
    multipleFieldsNode = (
      <div>
        <input
          className="form-control"
          data-input-error={checkInputError()}
          readOnly
          type="text"
          name={input_name}
          value={calculatedParameterValue().value}
        />
        {parameterInfoNodes}
      </div>
    )
  }

  var node;

  if (multipleFields) {
    node = (
      <div className="panel panel-white" >
        <div className="panel-heading">
          {multipleFieldsNode}
          {requiredHelpBlock}
          {characterLimitHelpBlock}
        </div>
        <div className="panel-body">
          {fieldsNodes}
        </div>
      </div>
    )
  } else {
    node = (
      <div>
        <input className="form-control"
          data-input-error={checkInputError()}
          readOnly
          type="hidden"
          name={input_name}
          value={calculatedParameterValue().value}
        />
        {parameterInfoNodes}
        {fieldsNodes}
        {requiredHelpBlock}
        {characterLimitHelpBlock}
      </div>
    )
  }
  return (
    <div>
      {node}
    </div>
  )
}

ParameterFormat.propTypes = {
  initial_value: PropTypes.string.isRequired,
  separator_populate: PropTypes.bool.isRequired,
  parameter_format: PropTypes.object.isRequired,
  initial_fields: PropTypes.array.isRequired,
  input_name: PropTypes.string.isRequired,
  pf_id_name: PropTypes.string.isRequired,
  fields_map_name: PropTypes.string.isRequired,
  prohibited_characters: PropTypes.array.isRequired,
  allow_multiple_field_values: PropTypes.bool.isRequired,
  max_character_limit: PropTypes.number
}