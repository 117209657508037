import React from 'react';
import PropTypes from 'prop-types'

export default class RenderHeading extends React.PureComponent {
  static propTypes = {
    header: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired
  }

  render () {
    let asterisk;

    if (this.props.required) {
      asterisk= <span className="text-warning"> * </span>
    }
    return <span>{this.props.header}{asterisk}</span>
  }
}
