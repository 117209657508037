import React, { useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import Select2 from 'react-select2-wrapper';

const STATUS_DEFAULT = 'DEFAULT';
const STATUS_DISABLE = 'DISABLE';
const STATUS_FORMAT_CONTROLLED = 'FORMAT_CONTROLLED';

export default function ConventionParamConfig({
  available_formats,
  base_input_name,
  format_id_input_name, // Form input for parameter_format_id e.g. 'campaign_format_id'
  disable_input_name, // Form input for disable column, e.g. 'campaign_disable'
  initial_format_id,
  initial_disable,
  required,
  destroy_for_default = false // If '_destroy' should be set when 'default' is selected.
}) {

  let initialStatus = STATUS_DEFAULT;
  if (initial_disable) {
    initialStatus = STATUS_DISABLE;
  } else if (initial_format_id) {
    initialStatus = STATUS_FORMAT_CONTROLLED;
  }

  const [status, setStatus] = useState(initialStatus);
  const [formatId, setFormatId] = useState(initial_format_id || '');

  const onValueChange = (event) => {
    setStatus(event.target.value);
  }

  const handleFormatIdChange = (event) => {
    const value = event.target.value;
    setFormatId(value);
  }

  const disable = status === STATUS_DISABLE;
  const finalFormatId = status === STATUS_FORMAT_CONTROLLED ? formatId : '';

  let destroyNode;

  if (status === STATUS_DEFAULT && destroy_for_default) {
    destroyNode = <input type="hidden" name={`${base_input_name}[_destroy]`} value='1' />
  }

  const disabled = required ? "disabled" : ""

  return (
    <div className="form-group">
      <div>
        <input type="hidden" name={`${base_input_name}[${format_id_input_name}]`} value={finalFormatId} />
        <input type="hidden" name={`${base_input_name}[${disable_input_name}]`} value={disable ? '1' : '0'} />
        {destroyNode}
      </div>

      <div className="radio">
        <label>
          <input
            type="radio"
            value={STATUS_DEFAULT}
            checked={status === STATUS_DEFAULT}
            onChange={onValueChange}
          />
          Default
          {status === STATUS_DEFAULT &&
            <span className='text-muted pl-10'>Use a default dropdown</span>
          }
        </label>
      </div>

      <div className={`radio ${disabled}`}>
        <label>
          <input
            type="radio"
            value={STATUS_DISABLE}
            checked={status === STATUS_DISABLE}
            onChange={onValueChange}
            disabled={disabled}
          />
          Disable
          {required &&
            <span className='text-muted pl-10'>Configured to be required and cannot be disabled</span>
          }
        </label>
      </div>

      <div className="radio">
        <label>
          <input
            type="radio"
            value={STATUS_FORMAT_CONTROLLED}
            checked={status === STATUS_FORMAT_CONTROLLED}
            onChange={onValueChange}
          />
          Enforce Format

          {status === STATUS_FORMAT_CONTROLLED &&
            <div className='format-select'>
              <Select2
                value={formatId}
                data={available_formats}
                onSelect={handleFormatIdChange}
                onClose={handleFormatIdChange}
                required={true}
                options={
                  {
                    width: '100%',
                    placeholder: "Select a format",
                    templateResult: terminus.select2DescriptionTemplate,
                    templateSelection: terminus.select2DescriptionTemplate
                  }
                }
              />
              {!formatId && <span className='text-danger'>Required</span>}
            </div>
          }
        </label>
      </div>
    </div>
  );
}

ConventionParamConfig.propTypes = {
  available_formats: PropTypes.array.isRequired,
  base_input_name: PropTypes.string.isRequired,
  format_id_input_name: PropTypes.string.isRequired,
  disable_input_name: PropTypes.string.isRequired,
  initial_format_id: PropTypes.string.isRequired,
  initial_disable: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  destroy_for_default: PropTypes.bool
};
