import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";

import ColumnHeader from './gridModeUrlBuilder/ColumnHeader';
import PinnedRowActionCellRenderer from './gridModeUrlBuilder/PinnedRowActionCellRenderer';
import TextCellEditor from './gridModeUrlBuilder/TextCellEditor';
import MultiValueTextCellEditor from './gridModeUrlBuilder/MultiValueTextCellEditor';
import DropdownCellEditor from './gridModeUrlBuilder/DropdownCellEditor';
import TextCellRenderer from './gridModeUrlBuilder/TextCellRenderer';
import MultiValueTextCellRenderer from './gridModeUrlBuilder/MultiValueTextCellRenderer';
import SingleSelectCellRenderer from './gridModeUrlBuilder/SingleSelectCellRenderer';
import MultiSelectCellRenderer from './gridModeUrlBuilder/MultiSelectCellRenderer';
import DateCellRenderer from './gridModeUrlBuilder/DateCellRenderer';
import DateCellEditor from './gridModeUrlBuilder/DateCellEditor';

import { onKeyDownTextNavigation } from './shared/KeyDown';

import FormData from './gridModeUrlBuilder/FormData';
import Cartesian from './shared/Cartesian';

import { useFields } from './conventionFields/useFields';
import { calculatedFormatValue } from './conventionFields/fieldHelpers';

import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-balham.css';

const ROW_COUNT_LIMIT = 120;

const GRID_URL_BUILDER_ID = "grid-mode-url-builder"
const DISABLED_CELL_STYLE = { backgroundColor: '#eeeeee' };
const ENABLED_CELL_STYLE = { backgroundColor: '#ffffff' };

// This is the same as in grid_mode.rb. Perhaps export those definitions?
const SINGLE_SELECT = 'single_select'
const MULTI_SELECT = 'multi_select'
const TEXT = 'text'
const CONSTANT = 'constant'
const DATE = 'date'
const FORMAT_CONTROLLED = 'format_controlled'
const PARENT_FIELD = 'parent_field'

const defaultColDef = {
  // set every column width
  width: 200,
  minWidth: 125,
  // maxWidth: 400,
  flex: 1,
  // make every column use 'text' filter by default
  filter: false,
  sortable: false,
  resizable: true,
  hide: false,
  floatingFilter: false,
  enableCellChangeFlash: false, // Flashes the format_controlled cells when any value changes
  // filterParams: ['reset'],
  // enableRowGroup: true
};

export default function GridModeUrlBuilder({
  url_columns,
  field_columns,
  other_columns,
  initial_row_data,
  initial_pinned_row_data,
}) {
  const allColumns = [...url_columns, ...field_columns, ...other_columns]

  const newEmptyRow = () => {
    let row = {};
    allColumns.forEach((column) => {
      row[column.field] = column.empty_value;
    });
    return row;
  }

  const initialPinnedTopRowData = () => {
    let row = {};
    allColumns.forEach((column) => {
      const initialValue = initial_pinned_row_data[column.field];
      const defaultValue = (column.column_type === SINGLE_SELECT || column.column_type === TEXT) ? [] : column.empty_value;
      row[column.field] = initialValue || defaultValue;
    });
    return [row];
  }

  const initialRowData = initial_row_data.length === 0 ? [newEmptyRow()] : initial_row_data;

  const [rowData, setRowData] = useState(initialRowData);
  const [pinnedTopRowData, setPinnedTopRowData] = useState(initialPinnedTopRowData());
  const [rowNodes, setRowNodes] = useState([]);
  const { fields, updateFieldValue } = useFields(field_columns);

  const [dataValid, setDataValid] = useState(true);
  const [saveClicked, setSaveClicked] = useState(false);

  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const headerHeight = 60;

  useEffect(() => {
    $('.tool-tip').tooltip({ container: 'body' });
  });

  const onGridReady = params => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    // params.api.sizeColumnsToFit();
    // Seems to break the grid when changing a cell for Eloqua and 
    // 'Conventions with fields shared between formats' conventions
    // params.columnApi.autoSizeAllColumns(false); 
  };

  const staticColumnDefs = useMemo(() => [
    {
      headerName: '',
      field: 'row_action',
      checkboxSelection: true,
      headerCheckboxSelection: true,
      // pinned: 'left', // maybe fixed in v27 NOTE: Enabling this breaks when calling any setState in useEffect e.g. refreshFieldVisibilty()
      resizable: false,
      floatingFilter: false,
      suppressSizeToFit: true,
      width: 40,
      minWidth: 40,
      maxWidth: 40,
      cellRendererSelector: (params) => {
        if (params.node.rowPinned) {
          return {
            component: PinnedRowActionCellRenderer
          };
        }
      },
    }
  ], []);

  const columnTypes = useMemo(() => ({
    selectColumn: {
      cellEditorPopup: true,
      cellEditor: DropdownCellEditor,
      suppressKeyboardEvent: (params) => {
        return params.editing;
      },
    }
  }), []);

  const selectValues = (values) => {
    return values.map((option) => {
      return { value: option.value, label: option.value, description: option.description, color: option.color }; // color is optional (perhaps create another renderer for labels)
    })
  }

  // These will be updated based on fieldColumns visibility
  const columns = [...url_columns, ...fields, ...other_columns]


  const defForColumn = (column) => {
    const commonDef = {
      headerName: column.header_name,
      field: column.field,
      editable: true,
      cellStyle: ENABLED_CELL_STYLE,
      headerComponentParams: {
        required: column.required,
        description: column.description,
        typeName: column.column_type_name,
        // isParent: column.is_parent,
        // options: column.is_parent ? selectValues(column.values) : []
      },
      headerTooltip: column.description,
      suppressPaste: true, // Pasting may violate constraints (until we have a better solution)
      hide: !column.visible,
      // Using cellRendererParams can be a work around to set custom properties on the column
      // Direct property setting on the column gives warnings in the console
      customData: column.custom_data || {} // Any data that's not directly used by the grid, renderer or editor
    }

    const textCellEditorParams = {
      textLimit: column.text_limit,
      allowedCharacters: column.allowed_characters,
      prohibitedCharacters: column.prohibited_characters,
    }

    switch (column.column_type) {

      case TEXT:
        return {
          ...commonDef,
          cellEditorSelector: params => {
            if (params.node.rowPinned && column.allow_combo_generate) {
              return {
                component: MultiValueTextCellEditor,
                params: {
                  ...textCellEditorParams,
                  onKeyDown: onKeyDownTextNavigation
                }
              }
            } else {
              return {
                component: TextCellEditor,
                params: textCellEditorParams
              }
            }
          },
          cellEditorPopup: true,
          cellRendererSelector: (params) => {
            if (params.node.rowPinned && column.allow_combo_generate) {
              return {
                component: MultiValueTextCellRenderer
              };
            } else {
              return {
                component: TextCellRenderer
              };
            }
          },
        }
      case SINGLE_SELECT:
        return {
          ...commonDef,
          type: ['selectColumn'],
          cellRendererSelector: (params) => {
            if (params.node.rowPinned && column.allow_combo_generate) {
              return {
                component: MultiSelectCellRenderer
              };
            } else {
              return {
                component: SingleSelectCellRenderer
              };
            }
          },
          cellEditorSelector: params => {
            return {
              component: DropdownCellEditor,
              params: {
                allowCreate: column.allow_create,
                multipleSelect: params.node.rowPinned && column.allow_combo_generate,
                options: selectValues(column.values),
                textLimit: column.text_limit,
                allowedCharacters: column.allowed_characters,
                prohibitedCharacters: column.prohibited_characters,
              }
            }
          }
        }
      case MULTI_SELECT:
        return {
          ...commonDef,
          type: ['selectColumn'],
          cellRenderer: MultiSelectCellRenderer,
          cellEditorParams: {
            allowCreate: column.allow_create,
            multipleSelect: true,
            options: selectValues(column.values)
          }
        }
      case CONSTANT:
        return {
          ...commonDef,
          editable: false,
          cellStyle: DISABLED_CELL_STYLE,
          // Even though empty row does this, valueGetter can make sure that it is never set to anything else
          // valueGetter: (params) => {
          //   return { value: column.constant_value };
          // },
          valueFormatter: (params) => {
            return params.value.value
          },
        }
      case DATE:
        return {
          ...commonDef,
          suppressKeyboardEvent: (params) => {
            return params.editing;
          },
          cellRenderer: DateCellRenderer,
          cellEditor: DateCellEditor,
          cellEditorParams: {
            dateFormat: column.date_format,
            portalId: GRID_URL_BUILDER_ID
          },
          valueSetter: (params) => {
            params.data[params.colDef.field] = params.newValue;
            return true
          },
        }
      case FORMAT_CONTROLLED: {
        return {
          ...commonDef,
          editable: false,
          cellStyle: DISABLED_CELL_STYLE,
          valueGetter: (params) => {
            if (params.node.rowPinned) {
              return { value: '' };
            } else {
              return formatControlledValueGetter(params);
            }
          },
          valueFormatter: (params) => {
            return params.value.value
          }
        }
      }
      case PARENT_FIELD:
        return {
          ...commonDef,
          type: ['selectColumn'],
          editable: (params) => {
            return params.node.rowPinned;
          },
          cellStyle: (params) => {
            return params.node.rowPinned ? ENABLED_CELL_STYLE : DISABLED_CELL_STYLE
          },
          cellRendererSelector: (params) => {
            if (params.node.rowPinned) {
              return {
                component: SingleSelectCellRenderer
              };
            } else {
              return {
                params: { style: DISABLED_CELL_STYLE },
              };
            }
          },
          valueGetter: (params) => {
            if (params.node.rowPinned) {
              return params.data[params.colDef.field]
            } else {
              return params.api.getPinnedTopRow(0).data[params.colDef.field]
            }
          },
          valueFormatter: (params) => {
            if (params.node.rowPinned) {
              return params.value
            } else {
              return params.value && params.value.value
            }
          },
          cellEditorParams: {
            allowCreate: false,
            multipleSelect: false,
            options: selectValues(column.values)
          }
        }
      default:
        return commonDef
    }
  }

  const dynamicColumnDefs = columns.map(column => {
    return defForColumn(column);
  });

  // TODO: columnDefs should be a state according to https://ag-grid.com/react-data-grid/react-hooks/#column-definitions
  // That's the reason the grid width and other stuff gets reset.
  const columnDefs = [...staticColumnDefs, ...dynamicColumnDefs]
  // const preColumnDefs = [...staticColumnDefs, ...dynamicColumnDefs]

  // TODO: This breaks selecting multipe values of child dropdowns in the pinned row
  // const [columnDefs, setColumnDefs] = useState(preColumnDefs);

  const activeFieldColumns = () => {
    // Use a hash for quick lookup
    let fieldColumns = {}
    if (gridColumnApi) {
      for (const column of gridColumnApi.columnModel.columnDefs) {
        if (column.field.startsWith('field_') && !column.hide) {
          fieldColumns[column.field] = column
        }
      }
    }
    return fieldColumns;
  }

  // Save the fields on the format_columns table rather than field ids.
  // Only ignore the field that have visible set to false, perhaps in calculatedFormatValue function
  // TODO: Move functions out of grid component if possible
  // Not sure if it's worth it, but it may be possible to not have customData on colDef, but use allColumns and filter field_ and visible ones.
  const formatControlledValueGetter = (params) => {
    let formatFields = [];
    let fieldColumns = activeFieldColumns();
    for (const fieldId of params.colDef.customData.field_ids) {
      const column = fieldColumns[`field_${fieldId}`]
      if (column) {
        // Get value via getValue to account for valueGetters
        const cellValueObject = params.api.getValue(column.field, params.node) || { value: '' }
        const field = {
          id: column.customData.id,
          name: column.customData.name,
          current_value: cellValueObject.value,
          prefix: column.customData.prefix,
          suffix: column.customData.suffix
        }
        formatFields.push(field)
      }
    }

    const result = calculatedFormatValue(formatFields, params.colDef.customData.separator)
    return { ...result, formatId: params.colDef.customData.format_id }
  }

  const updateRowNodes = (api, columnApi) => {
    let nodes = [];
    // For some reason gridApi is null when this is called.
    let valid = true;
    api.forEachNode((node) => {
      valid = valid && validateInputs(api, columnApi, node);
      nodes.push(node);
    });
    setDataValid(valid)
    setRowNodes(nodes);
  }

  const validateInputs = (api, columnApi, rowNode) => {
    let valid = true;

    for (const column of columns) {
      let valueObject = api.getValue(column.field, rowNode)
      let columnNode = columnApi.getColumn(column.field) // Required to get the last visible status (column.visible doesn't seem to be updated here)
      if (column.required && columnNode.visible) { // TODO: Test that hidden child fields are not enforced
        if (!valueObject || !valueObject.value || valueObject.value.length === 0) {
          valid = false
        }
      }
    }

    return valid;
  }

  // From ag-grid docs: 
  // If the application is doing work each time it receives a cellValueChanged, you can use the 
  // pasteStart and pasteEnd events to suspend the applications work and then do the work for 
  // all cells impacted by the paste operation after the paste operation.
  // This is needed to get a fresh set of rows for rendering the FormData.
  const onCellValueChanged = (params) => {
    // For a pinned row, update the field visiblity based on parent field selection
    if (params.node.isRowPinned()) {
      const customData = params.column.colDef.customData;
      const fieldId = customData && customData.id;
      if (fieldId) { // Only for field columns and not other columns like URL
        const value = params.newValue && params.newValue.value;
        updateFieldValue(fieldId, value);
        // setColumnDefs(preColumnDefs);
      }
    }
    updateRowNodes(params.api, params.columnApi);
  };

  const addRow = () => {
    gridApi.applyTransaction({ add: [newEmptyRow()] });
    updateRowNodes(gridApi, gridColumnApi);
  }

  const deleteRows = () => {
    const selectedRows = gridApi.getSelectedRows();
    gridApi.applyTransaction({ remove: selectedRows });
    updateRowNodes(gridApi, gridColumnApi);
    return true;
  }

  // Dupe in MultipleTagBuilder.js
  const enforceEmptyStringValue = (array) => {
    let withoutNullValues = array.filter(i => i);
    return (withoutNullValues.length > 0) ? withoutNullValues : [""]
  }

  const isComboColumn = (column) => {
    const columnNode = gridColumnApi.getColumn(column.field)
    return column.allow_combo_generate && columnNode.visible;
  }

  const generateCombinations = () => {
    const comboRowData = gridApi.getPinnedTopRow(0).data;

    let newAllRowsData = [];
    let cartesianArgs = [];

    for (const column of allColumns) {
      if (isComboColumn(column)) {
        const values = comboRowData[column.field].map(item => item.value);
        cartesianArgs.push(enforceEmptyStringValue([...new Set(values)]));
      }
    }

    const cartesian = new Cartesian(cartesianArgs);
    const results = cartesian.values();

    for (const row of results) {
      let newRow = {};
      let comboColumnIndex = 0;
      for (const column of allColumns) {
        if (isComboColumn(column)) {
          newRow[column.field] = { value: row[comboColumnIndex] };
          comboColumnIndex++;
        } else {
          newRow[column.field] = JSON.parse(JSON.stringify(comboRowData[column.field])); // Deep copy of array or hash
        }
      }
      newAllRowsData.push(newRow);
    }

    gridApi.setRowData(newAllRowsData)
    updateRowNodes(gridApi, gridColumnApi);
  }

  const processCellForClipboard = (params) => {
    return params.value && params.value.value || '';
  };

  const handleSubmit = (e) => {
    // This allows submit to proceed after click. Without this, the form does not submit 
    setTimeout(() => {
      setSaveClicked(true)
    }, 0);
  }

  const rowCountLimitExceeded = rowNodes.length > ROW_COUNT_LIMIT
  const saveDisabled = !dataValid || saveClicked || rowNodes.length === 0 || rowCountLimitExceeded;

  return (
    <div id={GRID_URL_BUILDER_ID} >
      <div className="mb-20">
        <a className='btn border-slate text-slate-800 btn-flat btn-xs mr-10' onClick={addRow}>Add row</a>
        <a className='btn border-danger text-danger btn-flat btn-xs mr-10' onClick={deleteRows}>Delete rows</a>
        <a className='btn alpha-blue btn-xs mr-10' onClick={generateCombinations}>Generate</a>
      </div>
      <div
        className="ag-theme-balham"
        style={{
          // height: '70vh', // Remove domLayout if using this https://www.ag-grid.com/react-data-grid/grid-size/
          height: '100%',
          width: '100%'
        }}
      >
        <AgGridReact
          ref={gridRef}
          components={{ agColumnHeader: ColumnHeader }}
          modules={[ClientSideRowModelModule]}
          rowData={rowData}
          pinnedTopRowData={pinnedTopRowData}
          columnDefs={columnDefs}
          columnTypes={columnTypes}
          onGridReady={onGridReady}
          headerHeight={headerHeight}
          domLayout={'autoHeight'}
          defaultColDef={defaultColDef}
          defaultColGroupDef={{}}
          rowGroupPanelShow={'never'}
          suppressMenuHide={true}
          // stopEditingWhenCellsLoseFocus={true} // Doesn't seem to work with DateCellEditor. No date is selected except the current date.
          rowSelection={'multiple'}
          checkboxSelection={true}
          suppressRowClickSelection={true}
          processCellForClipboard={processCellForClipboard}
          // enableRangeSelection={true} // Maybe fixed in v27 NOTE: Selecting parent field with option that shows a child field in pinned row crashes due to range selection. Not sure why.
          // enableFillHandle={true}
          // fillHandleDirection='y'
          autoGroupColumnDef={{ minWidth: 250 }}
          // This is needed to get the format controlled values to update if suppressChangeDetection is false. Why?
          onCellValueChanged={onCellValueChanged}
          popupParent={document.querySelector('body')} // Allows context menu to not be cut off by a small grid.
          tooltipShowDelay={0}
          suppressContextMenu={true} // Enable later when there's some useful context menu actions
        // suppressChangeDetection={true} // This is super slow to update when lots of rows are copied/pasted.
        >
        </AgGridReact>
      </div >
      {gridApi && gridColumnApi && <FormData gridApi={gridApi} columns={allColumns} />}


      {!dataValid && <div className='text-danger mt-20'>Please fill in all required fields</div>}
      {rowCountLimitExceeded && <div className="text-danger mt-20">You can build a maximum of {ROW_COUNT_LIMIT} URLs at one time</div>}
      <div className='text-muted text-size-small mt-20'>{rowNodes.length} URLs will be created</div>
      <div className="mt-20">
        <button
          type="submit"
          disabled={saveDisabled}
          onClick={handleSubmit}
          className="btn btn-primary">
          Save
        </button>
      </div>
    </div >
  );
};

// TODO: Fill this later
// GridModeUrlBuilder.propTypes = {
//   columns: PropTypes.array.isRequired
// }