import React from 'react';
import PropTypes from 'prop-types'
import Select2 from 'react-select2-wrapper';

class GenericSelect extends React.PureComponent {
  static propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    list: PropTypes.array,
    required: PropTypes.bool,
    inputName: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    highPerformanceMode: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleUnselect = this.handleUnselect.bind(this);
  }

  // NOTE: This is duplicate in MultipleValueInput
  tagData = (list, currentValue) => {
    let self = this;
    let valueIsInOptions = false;

    let data = list.map(function(item, index) {
      return {id: item.tag, text: item.tag, title: item.description};
    });

    // Select 2 needs any new values set on element to be present in items to show it.
    if (currentValue) {
      for (let item of list) {
        if ( item.tag === currentValue) {
          valueIsInOptions = true;
        }
      }
      if (!valueIsInOptions) {
        let newItem = {id: currentValue, text: currentValue, title: ''}
        data.push(newItem);
      }
    }

    let empty = [{id: null, text: null, title: null}]
    return empty.concat(data);
  }

  render () {
    let node = "";

    let selectOptions = { width: '100%',
                          allowClear: true,
                          tags: true,
                          placeholder: "Select/add " + this.props.label }

    if (this.props.highPerformanceMode) {
      node = <input
               type="text"
               value={this.props.value || ""}
               className="form-control"
               onChange={this.handleSelect}
               required={this.props.required}
               name={this.props.inputName}
             />
    } else {
      node = <Select2
               value={this.props.value}
               data={this.tagData(this.props.list, this.props.value)}
               required={this.props.required}
               name={this.props.inputName}
               onSelect={this.handleSelect}
               onUnselect={this.handleUnselect}
               options={selectOptions}
            />
    }
    return node;
  }

  handleSelect = (event) => {
    this.props.handleChange(event.target.value);
  }

  // Using onUnselect to avoid using onChange bug in Select2 wrapper
  // https://github.com/rkit/react-select2-wrapper/issues/63
  handleUnselect = (event) => {
    this.props.handleChange("");
  }
};

export default GenericSelect;
