import React from 'react';
import PropTypes from 'prop-types';
import OptionField from './OptionField';
import DateField from './DateField';
import TextField from './TextField';
import ConstantField from './ConstantField';

export default function Field({ field,
  showLabel,
  initialValue,
  updateFieldValue,
  updateMultipleFieldValues,
  prohibitedCharacters,
  allowMultipleFieldValues,
  showConstantField
}) {

  let inputNode;

  switch (field.type) {
    case "OptionField":
      inputNode = <OptionField
        field={field}
        showLabel={showLabel}
        initialValue={initialValue}
        updateFieldValue={updateFieldValue}
        prohibitedCharacters={prohibitedCharacters}
      />
      break;
    case "DateField":
      inputNode = <DateField
        field={field}
        showLabel={showLabel}
        initialValue={initialValue}
        updateFieldValue={updateFieldValue}
      />
      break;
    case "TextField":
      inputNode = <TextField
        field={field}
        showLabel={showLabel}
        initialValue={initialValue}
        updateFieldValue={updateFieldValue}
        updateMultipleFieldValues={updateMultipleFieldValues}
        prohibitedCharacters={prohibitedCharacters}
        allowMultipleFieldValues={allowMultipleFieldValues}
      />
      break;
    case "ConstantField":
      inputNode = (showConstantField &&
        <ConstantField
          field={field}
          showLabel={showLabel}
        />
      )
      break;
  }

  return (
    <>
      {inputNode &&
        <div className={"form-group field-id-" + field.id}>
          {inputNode}
        </div>
      }
    </>
  )
}

Field.propTypes = {
  field: PropTypes.object.isRequired,
  showLabel: PropTypes.bool.isRequired,
  initialValue: PropTypes.string,
  updateFieldValue: PropTypes.func.isRequired,
  updateMultipleFieldValues: PropTypes.func.isRequired,
  prohibitedCharacters: PropTypes.array.isRequired,
  allowMultipleFieldValues: PropTypes.bool.isRequired,
  showConstantField: PropTypes.bool.isRequired
};
