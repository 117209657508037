import React from 'react';
import PropTypes from 'prop-types'
import GenericSelect from '../multipleTagUrlBuilder/GenericSelect';

class ParameterSelect extends React.PureComponent {
  static propTypes = {
    parameterName: PropTypes.string,
    parameterLabel: PropTypes.string,
    value: PropTypes.string,
    list: PropTypes.array,
    required: PropTypes.bool,
    inputName: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    highPerformanceMode: PropTypes.bool.isRequired,
    autoGenerated: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  render() {
    let node = "";

    if (this.props.autoGenerated) {
      node = <input
        type="text"
        value={"Auto Generated"}
        className="form-control"
        disabled={true}
      />
    } else {
      node = <GenericSelect
        label={this.props.parameterLabel}
        value={this.props.value}
        list={this.props.list}
        required={this.props.required}
        inputName={this.props.inputName}
        handleChange={this.handleChange}
        highPerformanceMode={this.props.highPerformanceMode}
      />
    }
    return node;
  }

  handleChange = (value) => {
    this.props.handleChange(this.props.parameterName, value);
  }
};

export default ParameterSelect;
