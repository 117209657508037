import React from 'react';
import PropTypes from 'prop-types';
import PermissionFlag from './PermissionFlag';
import ParamsPermissionFlags from './ParamsPermissionFlags';

export default function Permission({ permission, project, inputNamePrefix, updatePermission, deletePermission }) {
  const updateFlag = (flagName, flagValue) => {
    updatePermission(permission.id, flagName, flagValue)
  }

  const handleDelete = () => {
    deletePermission(permission.id)
  }

  return (
    <tr className="project-permission">
      <td>
        <span> {project.name} </span>
        <input name={inputNamePrefix + "[project_id]"} type="hidden" value={permission.project_id} />
      </td>
      <td>
        <PermissionFlag
          inputNamePrefix={inputNamePrefix}
          flagName="write_urls"
          flagValue={permission.write_urls}
          updateFlag={updateFlag}
        />
      </td>
      <td>
        <ParamsPermissionFlags
          permission={permission}
          inputNamePrefix={inputNamePrefix}
          updateFlag={updateFlag}
        />
      </td>
      <td>
        <PermissionFlag
          inputNamePrefix={inputNamePrefix}
          flagName="write_presets"
          flagValue={permission.write_presets}
          updateFlag={updateFlag}
        />
      </td>
      <td>
        <PermissionFlag
          inputNamePrefix={inputNamePrefix}
          flagName="write_project"
          flagValue={permission.write_project}
          updateFlag={updateFlag}
        />
      </td>
      <td className="tags-remove">
        <a title="Remove"
          className="text-danger"
          tabIndex="-1"
          onClick={handleDelete}>
          <i className="icon-cross3" tabIndex="-1"></i>
          Remove
        </a>
      </td>
    </tr>
  );
}

Permission.propTypes = {
  permission: PropTypes.any.isRequired,
  project: PropTypes.any.isRequired,
  inputNamePrefix: PropTypes.string.isRequired,
  updatePermission: PropTypes.func.isRequired,
  deletePermission: PropTypes.func.isRequired
};
