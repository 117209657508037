import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import Select2 from 'react-select2-wrapper';

import CsrfToken from './shared/CsrfToken';
import guid from './shared/guid';
import Cartesian from './shared/Cartesian';
import ParametersInput from './multipleTagUrlBuilder/ParametersInput';
import MultipleValueInput from './multipleTagUrlBuilder/MultipleValueInput';
import RenderHeading from './multipleTagUrlBuilder/RenderHeading';
import ParameterSelect from './multipleTagUrlBuilder/ParameterSelect';
import LabelSelect from './multipleTagUrlBuilder/LabelSelect';
import ApplyTagButton from './multipleTagUrlBuilder/ApplyTagButton';
import cloneDeep from 'lodash/cloneDeep';

class MultipleTagUrlBuilder extends React.Component {
  static propTypes = {
    submit_url: PropTypes.string.isRequired,
    cancel_url: PropTypes.string.isRequired,
    preset_groups_url: PropTypes.string.isRequired,
    parameter_groups_url: PropTypes.string.isRequired,
    csrf: PropTypes.objectOf(PropTypes.string).isRequired,
    initial_urls: PropTypes.string,
    initial_builder_attrs_list: PropTypes.array,
    campaigns: PropTypes.array,
    media: PropTypes.array,
    sources: PropTypes.array,
    contents: PropTypes.array,
    terms: PropTypes.array,
    utm_campaign_required: PropTypes.bool.isRequired,
    utm_medium_required: PropTypes.bool.isRequired,
    utm_source_required: PropTypes.bool.isRequired,
    utm_content_required: PropTypes.bool.isRequired,
    utm_term_required: PropTypes.bool.isRequired,
    available_custom_parameters: PropTypes.array,
    available_info_fields: PropTypes.array,
    available_labels: PropTypes.array,
    presets: PropTypes.array,
    preset_groups: PropTypes.array,
    parameter_groups: PropTypes.array,
    allow_param_create: PropTypes.bool.isRequired,
    max_tags_count: PropTypes.number
  }

  constructor(props) {
    super(props);

    var initialBuilderAttrsList = this.props.initial_builder_attrs_list || [];
    var parametersList = initialBuilderAttrsList.map(function (builderAttrs, index) {
      // NOTE: Even though custom parameter now uses custom["coupon"] = { tag: "abc" } as the value,
      // we convert it back to simple custom_parameter["coupon] = "abc" for now to keep the code working
      // TODO: Make UTM and custom parts compatible with the param_hash structure {tag: "asf"}
      let customParameters = {};
      for (let key in builderAttrs.custom) {
        customParameters[key] = builderAttrs.custom[key].tag;
      }

      let infoFields = {};
      for (let key in builderAttrs.info) {
        infoFields[key] = builderAttrs.info[key].tag;
      }

      return {
        key: guid(),
        utmCampaign: builderAttrs.utm.campaign.tag,
        utmMedium: builderAttrs.utm.medium.tag,
        utmSource: builderAttrs.utm.source.tag,
        utmContent: builderAttrs.utm.content.tag,
        utmTerm: builderAttrs.utm.term.tag,
        customParameters: customParameters || {},
        infoFields: infoFields,
        labelNames: builderAttrs.label_names || [],
        description: builderAttrs.description
      }
    });

    if (parametersList.length == 0) {
      parametersList = [
        this.newParameterSet(),
        this.newParameterSet()
      ];
    }

    parametersList = this.parametersListWithUpdatedRemoveIconStatus(parametersList);

    this.handleParameterValueChange = this.handleParameterValueChange.bind(this);
    this.handleLabelNamesChange = this.handleLabelNamesChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handlePresetChange = this.handlePresetChange.bind(this);
    this.handleRemoveParameters = this.handleRemoveParameters.bind(this);
    this.handleCloneParameters = this.handleCloneParameters.bind(this);
    this.applyMultipleValueCombination = this.applyMultipleValueCombination.bind(this);
    this.disableSubmit = this.disableSubmit.bind(this);
    this.applyToFields = this.applyToFields.bind(this)
    this.applyPushLabel = this.applyPushLabel.bind(this)

    this.handleCommonUtmValuesChange = this.handleCommonUtmValuesChange.bind(this);
    this.handleCommonCustomParameterValueChange = this.handleCommonCustomParameterValueChange.bind(this);
    this.handleCommonInfoFieldValueChange = this.handleCommonInfoFieldValueChange.bind(this);
    this.handleCommonLabelNamesChange = this.handleCommonLabelNamesChange.bind(this);
    this.handleCommonDescriptionChange = this.handleCommonDescriptionChange.bind(this);

    this.state = {
      urls: this.props.initial_urls || "",
      parametersList: parametersList,
      submitDisabled: false,
      parameterError: false,
      highPerformanceMode: false,
      multipleParameterValues: {
        utmCampaigns: [],
        utmMedia: [],
        utmSources: [],
        utmContents: [],
        utmTerms: [],
        customParameters: {},
        infoFields: [],
        labels: [],
        description: ""
      }
    }
  }

  // Required for OptionValue tooltips
  componentDidMount() {
    $('.tool-tip').tooltip({ container: 'body' });
  }

  newParameterSet = () => {
    return { key: guid(), customParameters: {}, infoFields: [], labelNames: [] };
  }

  presetGroupsSelectData = () => {
    var data = this.props.preset_groups.map(function (presetGroup, index) {
      return { id: presetGroup.id, text: presetGroup.name, title: presetGroup.name };
    });
    return data;
  }

  parameterGroupsSelectData = () => {
    var data = this.props.parameter_groups.map(function (parameterGroup, index) {
      return { id: parameterGroup.id, text: parameterGroup.name, title: parameterGroup.name };
    });
    return data;
  }

  parametersNodes = () => {
    let self = this;

    // NOTE: Passing an "index" and binding the handlers in constructer saves a lot of unnecessary rerender
    // due to changing functions
    let nodes = this.state.parametersList.map(function (parameterSet, index) {
      return (
        <ParametersInput
          key={parameterSet.key}
          index={index}
          campaigns={self.props.campaigns}
          media={self.props.media}
          sources={self.props.sources}
          contents={self.props.contents}
          terms={self.props.terms}
          availableCustomParameters={self.props.available_custom_parameters}
          availableInfoFields={self.props.available_info_fields}
          availableLabels={self.props.available_labels}
          presets={self.props.presets}
          utmCampaign={parameterSet.utmCampaign}
          utmMedium={parameterSet.utmMedium}
          utmSource={parameterSet.utmSource}
          utmContent={parameterSet.utmContent}
          utmTerm={parameterSet.utmTerm}
          customParameters={parameterSet.customParameters}
          infoFields={parameterSet.infoFields}
          labelNames={parameterSet.labelNames}
          utmCampaignRequired={self.props.utm_campaign_required}
          utmMediumRequired={self.props.utm_medium_required}
          utmSourceRequired={self.props.utm_source_required}
          utmContentRequired={self.props.utm_content_required}
          utmTermRequired={self.props.utm_term_required}
          description={parameterSet.description}
          preset={parameterSet.preset}
          handleParameterValueChange={self.handleParameterValueChange}
          handleLabelNamesChange={self.handleLabelNamesChange}
          handleDescriptionChange={self.handleDescriptionChange}
          handlePresetChange={self.handlePresetChange}
          showRemoveIcon={parameterSet.showRemoveIcon}
          handleRemove={self.handleRemoveParameters}
          handleClone={self.handleCloneParameters}
          allowCreate={self.props.allow_param_create}
          highPerformanceMode={self.state.highPerformanceMode}
        />
      );
    });

    return nodes;
  }

  render() {
    var self = this;

    var parameterErrorNode;

    if (this.state.parameterError) {
      var requiredUtmParams = [];

      if (this.props.utm_campaign_required) requiredUtmParams.push("Campaign");
      if (this.props.utm_medium_required) requiredUtmParams.push("Medium");
      if (this.props.utm_source_required) requiredUtmParams.push("Source");
      if (this.props.utm_content_required) requiredUtmParams.push("Content");
      if (this.props.utm_term_required) requiredUtmParams.push("Term");

      var params = requiredUtmParams.join(", ");

      parameterErrorNode = (
        <span className="text-danger">
          Make sure all {params} values are filled.
        </span>
      );
    }

    let customParameterHeaders = this.props.available_custom_parameters.map(function (customParameter, index) {
      return (
        <th key={customParameter.id}><RenderHeading header={customParameter.name} required={customParameter.required} /></th>
      )
    });

    let infoFieldHeaders = this.props.available_info_fields.map(function (infoField, index) {
      return (
        <th key={infoField.id}><RenderHeading header={infoField.name} required={infoField.required} /></th>
      )
    });


    let destinationUrlsNode = (
      <div className="form-group">
        <label className="control-label" htmlFor="multiple_tag_url_builder_url">
          Destination URLs
          <span className="text-warning"> * </span>
        </label>
        <textarea
          className="form-control"
          autoFocus={true}
          id="multiple_tag_url_builder_url"
          name="multiple_tag_url_builder[urls]"
          required="required"
          rows="3"
          value={this.state.urls}
          onChange={this.handleUrlChange}
        />
        <span className="help-block">
          Enter each URL on a separate line
        </span>
      </div>
    )

    let presetGroupSelectorNode = (
      <div className="form-group">
        <label className="control-label pt-5" htmlFor="presetGroup">
          Apply a Preset Group
          <a className="tool-tip position-right"
            data-toggle="tooltip"
            data-placement="top"
            data-trigger="hover"
            title="Replaces the table below with presets from the selected preset group">
            <i className="icon-info3 text-muted text-size-small"></i>
          </a>
        </label>
        <Select2
          value={this.state.presetGroupValue}
          data={this.presetGroupsSelectData()}
          onSelect={this.handlePresetGroupChange}
          options={
            {
              width: '100%',
              allowClear: true,
              placeholder: "Select a Preset Group"
            }
          }
        />
      </div>
    )

    let parameterGroupSelectorNode = (
      <div className="row">
        <div className="col-md-6">
          <Select2
            value={this.state.parameterGroupValue}
            data={this.parameterGroupsSelectData()}
            onSelect={this.handleParameterGroupChange}
            options={
              {
                width: '100%',
                allowClear: true,
                placeholder: "Use UTMs from a Parameter Group"
              }
            }
          />
        </div>
        <div className="col-md-6">
          <label className="control-label pt-10" htmlFor="parameterGroup">
            Or select below
          </label>
        </div>
      </div>
    )

    let utmBgColor = "alpha-teal";
    let customParamBgColor = "alpha-blue";
    let infoFieldBgColor = "alpha-orange";
    let actionBgColor = "alpha-slate";
    let otherBgColor = "alpha-slate";

    let combinationNode = (
      <td rowSpan="3" className={actionBgColor}>
        <a className="btn bg-teal btn-xs m-5"
          href="#" // Required for tests only
          onClick={this.applyMultipleValueCombination}>
          <i className="icon-plus22"></i>
          Generate
        </a>
        <a className="btn btn-default btn-xs m-5"
          onClick={this.clearParametersList}>
          <i className="icon-cross3"></i>
          Clear
        </a>
        <span className="text-muted text-size-mini display-block m-5">Use it to populate rows below</span>
      </td>
    )

    var commonCustomParametersNodes = this.props.available_custom_parameters.map(function (customParameter, index) {
      return (
        <td key={customParameter.id} className={customParamBgColor}>
          <ParameterSelect
            parameterName={customParameter.name}
            parameterLabel={customParameter.name}
            value={""}
            list={customParameter.custom_parameter_values}
            required={false}
            inputName={""}
            handleChange={self.handleCommonCustomParameterValueChange}
            highPerformanceMode={false}
            autoGenerated={customParameter.either_generated}
          />
        </td>
      )
    });

    var commonInfoFieldNodes = this.props.available_info_fields.map(function (infoField, index) {
      return (
        <td key={infoField.id} className={infoFieldBgColor}>
          <ParameterSelect
            parameterName={infoField.name}
            parameterLabel={infoField.name}
            value={""}
            list={infoField.active_info_field_values}
            required={false}
            inputName={""}
            handleChange={self.handleCommonInfoFieldValueChange}
            highPerformanceMode={false}
            autoGenerated={infoField.generated}
          />
        </td>
      )
    });

    let commonLabelNamesNode = <LabelSelect
      labelNames={this.state.multipleParameterValues.labelNames}
      availableLabels={this.props.available_labels}
      inputName=""
      handleChange={self.handleCommonLabelNamesChange}
    />


    var commonDescriptionNode = <input
      value={this.state.multipleParameterValues.description}
      type="text"
      onChange={self.handleCommonDescriptionChange}
      className="form-control"
    />

    var applyCustomParameterTagNodes = this.props.available_custom_parameters.map(function (customParameter, index) {
      return (
        <td className={customParamBgColor}>
          <ApplyTagButton
            inputValues={self.state.multipleParameterValues.customParameters[customParameter.name]}
            fieldName={customParameter.name}
            isCustomParameter={true}
            onApplyFieldsClick={self.applyToFields}
          />
        </td>
      )
    });

    var applyInfoFieldTagNodes = this.props.available_info_fields.map(function (infoField, index) {
      return (
        <td className={infoFieldBgColor}>
          <ApplyTagButton
            inputValues={self.state.multipleParameterValues.infoFields[infoField.name]}
            fieldName={infoField.name}
            isInfoField={true}
            onApplyFieldsClick={self.applyToFields}
          />
        </td>
      )
    });

    let customParametersEmptyHeader;
    if (this.props.available_custom_parameters.length > 0) {
      customParametersEmptyHeader = <td colSpan={this.props.available_custom_parameters.length} className={customParamBgColor}></td>
    }

    let infoFieldsEmptyHeader;
    if (this.props.available_info_fields.length > 0) {
      infoFieldsEmptyHeader = <td colSpan={this.props.available_info_fields.length} className={infoFieldBgColor}></td>
    }

    let commonParamsHeaderRow = (
      <tr>
        {combinationNode}
        <td colSpan="5" className={utmBgColor}>
          {parameterGroupSelectorNode}
        </td>
        {customParametersEmptyHeader}
        {infoFieldsEmptyHeader}
        <td className={otherBgColor}></td>
        <td className={otherBgColor}></td>
      </tr>
    )
    let commonParamsNode = (
      <tr>
        <td className={utmBgColor}>
          <MultipleValueInput
            currentValues={this.state.multipleParameterValues.utmCampaigns}
            label={'Campaigns'}
            parameterName={'utmCampaigns'}
            availableValues={this.props.campaigns}
            handleChange={this.handleCommonUtmValuesChange}
          />
        </td>
        <td className={utmBgColor}>
          <MultipleValueInput
            currentValues={this.state.multipleParameterValues.utmMedia}
            label={'Mediums'}
            parameterName={'utmMedia'}
            availableValues={this.props.media}
            handleChange={this.handleCommonUtmValuesChange}
          />
        </td>
        <td className={utmBgColor}>
          <MultipleValueInput
            currentValues={this.state.multipleParameterValues.utmSources}
            label={'Sources'}
            parameterName={'utmSources'}
            availableValues={this.props.sources}
            handleChange={this.handleCommonUtmValuesChange}
          />
        </td>
        <td className={utmBgColor}>
          <MultipleValueInput
            currentValues={this.state.multipleParameterValues.utmContents}
            label={'Contents'}
            parameterName={'utmContents'}
            availableValues={this.props.contents}
            handleChange={this.handleCommonUtmValuesChange}
          />
        </td>
        <td className={utmBgColor}>
          <MultipleValueInput
            currentValues={this.state.multipleParameterValues.utmTerms}
            label={'Terms'}
            parameterName={'utmTerms'}
            availableValues={this.props.terms}
            handleChange={this.handleCommonUtmValuesChange}
          />
        </td>
        {commonCustomParametersNodes}
        {commonInfoFieldNodes}
        <td className={otherBgColor}>{commonLabelNamesNode}</td>
        <td className={otherBgColor}>{commonDescriptionNode}</td>
      </tr>
    )

    
    let applySelectedTagHeader = (
      <tr>
        <td className={utmBgColor}>
          <ApplyTagButton 
            inputValues={this.state.multipleParameterValues.utmCampaigns}
            fieldName="utmCampaign"
            onApplyFieldsClick={self.applyToFields}
          />
        </td>
        <td className={utmBgColor}>
          <ApplyTagButton
            inputValues={this.state.multipleParameterValues.utmMedia}
            fieldName="utmMedium"
            onApplyFieldsClick={self.applyToFields}
          />
        </td>
        <td className={utmBgColor}>
          <ApplyTagButton
            inputValues={this.state.multipleParameterValues.utmSources}
            fieldName="utmSource"
            onApplyFieldsClick={self.applyToFields}
          />
        </td>
        <td className={utmBgColor}>
          <ApplyTagButton
            inputValues={this.state.multipleParameterValues.utmContents}
            fieldName="utmContent"
            onApplyFieldsClick={self.applyToFields}
          />
        </td>
        <td className={utmBgColor}>
          <ApplyTagButton
            inputValues={this.state.multipleParameterValues.utmTerms}
            fieldName="utmTerm"
            onApplyFieldsClick={self.applyToFields}
          />
        </td>
        {applyCustomParameterTagNodes}
        {applyInfoFieldTagNodes}
        <td className={otherBgColor}>
          <ApplyTagButton
            inputValues={this.state.multipleParameterValues.labelNames}
            fieldName="labelNames"
            isLabelField={true}
            onApplyFieldsClick={self.applyToFields}
            onApplyPushLabelClick={self.applyPushLabel}
          />
        </td>
        <td className={otherBgColor}>
          <ApplyTagButton
            inputValues={this.state.multipleParameterValues.description}
            fieldName="description"
            isNoteField={true}
            onApplyFieldsClick={self.applyToFields}
          />
        </td>
      </tr>
    )

    let orNode = (
      <div className="row">
        <div className="col-xs-5">
          <hr />
        </div>
        <div className="col-xs-2">
          <div className="text-center pt-10">
            <span className="text-muted">AND/OR</span>
          </div>
        </div>
        <div className="col-xs-5">
          <hr />
        </div>
      </div>
    )

    let maxUrlsCountReachedErrorNode;

    if (this.tooManyTaggedUrls()) {
      maxUrlsCountReachedErrorNode = (
        <div className="row mb-10">
          <div className="col-md-6 mt-10">
            <span className="text-danger">You can build a maximum of {this.props.max_tags_count} URLs at one time</span>
          </div>
        </div>
      )
    }

    let parametersMarkup = <div className="table-responsive">
      <table className='table table-borderless table-xs multiple-tags-url-builder'>
        <thead>
          <tr>
            <th>Actions</th>
            <th><RenderHeading header="Campaign" required={this.props.utm_campaign_required} /></th>
            <th><RenderHeading header="Medium" required={this.props.utm_medium_required} /></th>
            <th><RenderHeading header="Source" required={this.props.utm_source_required} /></th>
            <th><RenderHeading header="Content" required={this.props.utm_content_required} /></th>
            <th><RenderHeading header="Term" required={this.props.utm_term_required} /></th>
            {customParameterHeaders}
            {infoFieldHeaders}
            <th><RenderHeading header="Labels" required={false} /></th>
            <th><RenderHeading header="Note" required={false} /></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {commonParamsHeaderRow}
          {commonParamsNode}
          {applySelectedTagHeader}
          {self.parametersNodes()}
        </tbody>
      </table>
    </div>


    return (
      <div>
        <form action={this.props.submit_url}
          acceptCharset="UTF-8"
          method="post"
          className="multiple-tags-url-builder"
          onSubmit={this.handleSubmit}>

          <fieldset className="content-group">
            <CsrfToken csrf={this.props.csrf} />

            <div className="row">
              <div className="col-md-6">
                {destinationUrlsNode}
              </div>

              <div className="col-md-2 col-md-offset-2">
                {presetGroupSelectorNode}
              </div>
            </div>

            <div className="row mb-10">
              <div className="col-md-6 mt-10">
                <span className="text-muted text-size-small text-bold">&nbsp;{this.taggedUrlsCount()}</span>
                <span className="text-muted text-size-small">&nbsp;tagged urls will be created</span>
                <a className="tool-tip position-right"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-trigger="hover"
                  title="Each set of these parameters will be applied to each URL above">
                  <i className="icon-info3 text-muted text-size-small"></i>
                </a>
              </div>
            </div>

            {maxUrlsCountReachedErrorNode}

            {parametersMarkup}

          </fieldset>

          <div className="mb-20">
            <a onClick={this.handleAddParameters}>
              <i className="icon-plus22 position-left"></i>
              Add another
            </a>
          </div>

          <div className="mb-20">
            {parameterErrorNode}
          </div>

          <div className="form-group">
            <button name="button" type="submit" disabled={this.disableSubmit()} className="btn btn-primary">Save</button>
            &nbsp;
            &nbsp;
            <a href={this.props.cancel_url}>Cancel</a>
          </div>
          {maxUrlsCountReachedErrorNode}
        </form>
      </div>
    );
  }

  handleUrlChange = (e) => {
    var urls = e.target.value;
    this.setState({ urls: urls });
  }

  urlsCount = () => {
    var lines = this.state.urls.split(/\r*\n/);
    var nonEmptylines = lines.filter(function (line) {
      return line.length > 0;
    });

    return nonEmptylines.length;
  }

  taggedUrlsCount = () => {
    return this.state.parametersList.length * this.urlsCount();
  }

  handleSubmit = (e) => {
    if (!this.validate()) {
      e.preventDefault();
      this.setState({ parameterError: true });
    } else {
      this.setState({ submitDisabled: true });
    }
  }

  disableSubmit = () => {
    return this.state.submitDisabled || this.tooManyTaggedUrls();
  }

  clearParametersList = () => {
    var newParametersList = update(
      this.state.parametersList, { $set: [] }
    );
    newParametersList = this.parametersListWithUpdatedRemoveIconStatus(newParametersList);
    this.setState({ highPerformanceMode: false, parametersList: newParametersList });
  }

  handleAddParameters = () => {
    var newParametersList = update(
      this.state.parametersList, { $push: [this.newParameterSet()] }
    );
    newParametersList = this.parametersListWithUpdatedRemoveIconStatus(newParametersList);
    this.setState({ parametersList: newParametersList });
  }

  handleRemoveParameters = (index) => {
    var newParametersList = update(
      this.state.parametersList, { $splice: [[index, 1]] }
    );

    newParametersList = this.parametersListWithUpdatedRemoveIconStatus(newParametersList);
    this.setState({ parametersList: newParametersList });
  }

  handleCloneParameters = (index) => {
    let clonedParameterSet = cloneDeep(this.state.parametersList[index]);
    clonedParameterSet.key = guid();

    let newParametersList = update(
      this.state.parametersList, { $splice: [[index + 1, 0, clonedParameterSet]] }
    );

    newParametersList = this.parametersListWithUpdatedRemoveIconStatus(newParametersList);
    this.setState({ parametersList: newParametersList });
  }

  parametersListWithUpdatedRemoveIconStatus = (parametersList) => {
    var updatedParametersList = parametersList;

    if (updatedParametersList.length === 1) {
      updatedParametersList[0].showRemoveIcon = false;
    } else {
      updatedParametersList.map(function (parameters) {
        parameters.showRemoveIcon = true;
      });
    }

    return updatedParametersList;
  }

  handleParameterValueChange = (index, parameterName, value, isCustomParameter, isInfoField) => {
    var newParametersList;

    if (isCustomParameter) {
      newParametersList = update(
        this.state.parametersList, { [index]: { customParameters: { [parameterName]: { $set: value } } } }
      );
    } else if (isInfoField) {
      newParametersList = update(
        this.state.parametersList, { [index]: { infoFields: { [parameterName]: { $set: value } } } }
      );
    } else {
      newParametersList = update(
        this.state.parametersList, { [index]: { [parameterName]: { $set: value } } }
      );
    }

    this.setState({ parametersList: newParametersList, parameterError: false });
  }

  handleLabelNamesChange = (index, labelNames) => {
    let newParametersList = update(this.state.parametersList, { [index]: { labelNames: { $set: labelNames } } });
    this.setState({ parametersList: newParametersList });
  }

  handleDescriptionChange = (index, value) => {
    let newParametersList = update(this.state.parametersList, { [index]: { description: { $set: value } } });
    this.setState({ parametersList: newParametersList });
  }

  handlePresetChange = (index, presetId) => {
    var preset = this.findObjectById(this.props.presets, presetId);
    var newParametersList = this.presetChangeForList(this.state.parametersList, index, preset);
    this.setState({ parametersList: newParametersList, parameterError: false });
  }

  presetChangeForList = (list, index, selectedPreset) => {
    let utmCampaign = selectedPreset && selectedPreset.utm_campaign;
    let utmMedium = selectedPreset && selectedPreset.utm_medium;
    let utmSource = selectedPreset && selectedPreset.utm_source;
    let utmContent = selectedPreset && selectedPreset.utm_content;
    let utmTerm = selectedPreset && selectedPreset.utm_term;
    let customParameterValues = selectedPreset && selectedPreset.custom_parameter_values || [];

    let customParameters = {};
    for (const customParameterValue of customParameterValues) {
      customParameters[customParameterValue.custom_parameter_name] = customParameterValue.tag;
    }

    let newParametersList = update(
      list, {
      [index]: {
        preset: { $set: selectedPreset },
        utmCampaign: { $set: utmCampaign },
        utmMedium: { $set: utmMedium },
        utmSource: { $set: utmSource },
        utmContent: { $set: utmContent },
        utmTerm: { $set: utmTerm },
        customParameters: { $set: customParameters }
      }
    }
    );
    return newParametersList;
  }

  handlePresetGroupChange = (event) => {
    var presetGroupId = event.target.value
    var selectedPresetGroup = this.findObjectById(this.props.preset_groups, presetGroupId);
    var presetIds = selectedPresetGroup.preset_ids;
    var newParametersList = [];
    var parameterIndex = 0;

    for (var i = 0, l = this.props.presets.length; i < l; i++) {
      var preset = this.props.presets[i];
      if (presetIds.indexOf(preset.id) !== -1) {
        newParametersList.push(this.newParameterSet());
        newParametersList = this.presetChangeForList(newParametersList, parameterIndex, preset);
        parameterIndex++;
      }
    }

    newParametersList = this.parametersListWithUpdatedRemoveIconStatus(newParametersList);
    this.setState({ presetGroupValue: selectedPresetGroup.id, parametersList: newParametersList });
  }

  handleParameterGroupChange = (event) => {
    var parameterGroupId = event.target.value
    var selectedParameterGroup = this.findObjectById(this.props.parameter_groups, parameterGroupId);

    this.setState({ parameterGroupValue: selectedParameterGroup.id });
    this.handleCommonUtmValuesChange("utmCampaigns", selectedParameterGroup.utm_campaigns);
    this.handleCommonUtmValuesChange("utmMedia", selectedParameterGroup.utm_media);
    this.handleCommonUtmValuesChange("utmSources", selectedParameterGroup.utm_sources);
    this.handleCommonUtmValuesChange("utmContents", selectedParameterGroup.utm_contents);
    this.handleCommonUtmValuesChange("utmTerms", selectedParameterGroup.utm_terms);
  }

  findObjectById = (list, id) => {
    var selectedObject;

    if (id) {
      for (var i = 0, l = list.length; i < l; i++) {
        var object = list[i];
        if (object.id.toString() === id) {
          selectedObject = object;
        }
      }
    }

    return selectedObject;
  }

  /* TODO: This validation is duplicate from Rails. It prevents
   * unnecessary submissions, but it's not good to be here */
  validate = () => {
    var globalValid = true;

    for (var i = 0; i < this.state.parametersList.length; i++) {
      var parameterSet = this.state.parametersList[i];
      var valid = (!this.props.utm_campaign_required || parameterSet.utmCampaign) &&
        (!this.props.utm_medium_required || parameterSet.utmMedium) &&
        (!this.props.utm_source_required || parameterSet.utmSource) &&
        (!this.props.utm_content_required || parameterSet.utmContent) &&
        (!this.props.utm_term_required || parameterSet.utmTerm);
      globalValid = globalValid && valid;
    }

    return globalValid;
  }

  tooManyTaggedUrls = () => {
    return this.taggedUrlsCount() > this.props.max_tags_count
  }

  handleCommonUtmValuesChange = (parameterName, values) => {
    let newMultipleParameterValues = update(
      this.state.multipleParameterValues, { [parameterName]: { $set: values } }
    );

    this.setState({ multipleParameterValues: newMultipleParameterValues })
  }

  handleCommonCustomParameterValueChange = (customParameterName, value) => {
    let newMultipleParameterValues = update(
      this.state.multipleParameterValues, { customParameters: { [customParameterName]: { $set: value } } }
    );

    this.setState({ multipleParameterValues: newMultipleParameterValues })
  }

  handleCommonInfoFieldValueChange = (infoFieldName, value) => {
    let newMultipleParameterValues = update(
      this.state.multipleParameterValues, { infoFields: { [infoFieldName]: { $set: value } } }
    );

    this.setState({ multipleParameterValues: newMultipleParameterValues })
  }

  handleCommonLabelNamesChange = (labelNames) => {
    let newMultipleParameterValues = update(
      this.state.multipleParameterValues, { labelNames: { $set: labelNames } }
    );

    this.setState({ multipleParameterValues: newMultipleParameterValues })
  }

  handleCommonDescriptionChange = (event) => {
    let value = event.target.value;
    let newMultipleParameterValues = update(
      this.state.multipleParameterValues, { description: { $set: value } }
    );

    this.setState({ multipleParameterValues: newMultipleParameterValues })
  }

  applyMultipleValueCombination = () => {
    let self = this;
    let newParametersList = [];

    let currentCampaigns = this.state.parametersList.map(x => x.utmCampaign);
    let currentMedia = this.state.parametersList.map(x => x.utmMedium);
    let currentSources = this.state.parametersList.map(x => x.utmSource);
    let currentContents = this.state.parametersList.map(x => x.utmContent);
    let currentTerms = this.state.parametersList.map(x => x.utmTerm);

    let newCampaigns = this.state.multipleParameterValues.utmCampaigns;
    let newMedia = this.state.multipleParameterValues.utmMedia;
    let newSources = this.state.multipleParameterValues.utmSources;
    let newContents = this.state.multipleParameterValues.utmContents;
    let newTerms = this.state.multipleParameterValues.utmTerms;

    let allCampaigns = newCampaigns.concat(currentCampaigns);
    let allMedia = newMedia.concat(currentMedia);
    let allSources = newSources.concat(currentSources);
    let allContents = newContents.concat(currentContents);
    let allTerms = newTerms.concat(currentTerms);

    let cartesianArgs = [
      this.enforceEmptyStringValue([...new Set(allCampaigns)]),
      this.enforceEmptyStringValue([...new Set(allMedia)]),
      this.enforceEmptyStringValue([...new Set(allSources)]),
      this.enforceEmptyStringValue([...new Set(allContents)]),
      this.enforceEmptyStringValue([...new Set(allTerms)]),
    ]

    let results = new Cartesian(cartesianArgs).values();

    for (let i = 0; i < results.length; i++) {
      let resultSet = results[i];
      let parameterSet = this.newParameterSet();
      parameterSet.utmCampaign = resultSet[0];
      parameterSet.utmMedium = resultSet[1];
      parameterSet.utmSource = resultSet[2];
      parameterSet.utmContent = resultSet[3];
      parameterSet.utmTerm = resultSet[4];
      parameterSet.customParameters = self.state.multipleParameterValues.customParameters;
      parameterSet.infoFields = self.state.multipleParameterValues.infoFields;
      parameterSet.labelNames = self.state.multipleParameterValues.labelNames;
      parameterSet.description = self.state.multipleParameterValues.description;
      newParametersList.push(parameterSet);
    }

    newParametersList = this.parametersListWithUpdatedRemoveIconStatus(newParametersList);

    let newHighPerformanceMode = newParametersList.length > this.props.max_tags_count;
    this.setState({ highPerformanceMode: newHighPerformanceMode, parametersList: newParametersList });
  }

  enforceEmptyStringValue = (array) => {
    let withoutNullValues = array.filter(i => i);
    return (withoutNullValues.length > 0) ? withoutNullValues : [""]
  }

  applyToFields = (fieldName, inputValues, isCustomParameter, isInfoField, overRide) => {
    let newParametersList = [];
    let parametersSet;

    for (let i = 0; i < this.state.parametersList.length; i++) {
      if (isCustomParameter && (overRide || !this.state.parametersList[i].customParameters[fieldName])) {
        parametersSet = update(
          this.state.parametersList[i], { customParameters: { [fieldName]: { $set: inputValues } } }
        )
      } else if (isInfoField && (overRide || !this.state.parametersList[i].infoFields[fieldName])) {
        parametersSet = update(
          this.state.parametersList[i], { infoFields: { [fieldName]: { $set: inputValues } } }
        )
      } else if (overRide || (!this.state.parametersList[i][fieldName] || !this.state.parametersList[i][fieldName].length)) {
        parametersSet = update(
          this.state.parametersList[i], { [fieldName]: { $set: inputValues } }
        )
      } else {
        parametersSet = this.state.parametersList[i]
      }
      newParametersList.push(parametersSet)
    }

    newParametersList = this.parametersListWithUpdatedRemoveIconStatus(newParametersList);
    this.setState({ parametersList: newParametersList })
  }

  applyPushLabel = (inputValues) => {
    let newParametersList = [];

    for (let i = 0; i < this.state.parametersList.length; i++) {
      let allLabels = inputValues.concat(this.state.parametersList[i].labelNames)
      let parametersSet = update(
        this.state.parametersList[i], { labelNames: { $set: [...new Set(allLabels)] } }
      )
      newParametersList.push(parametersSet)
    }

    newParametersList = this.parametersListWithUpdatedRemoveIconStatus(newParametersList);
    this.setState({ parametersList: newParametersList})
  }
};

export default MultipleTagUrlBuilder;
