import React from 'react'
import PropTypes from 'prop-types';

export default function IndeterminateCheckbox(props) {
  const { isIndeterminate, ...otherProps } = props

  const setCheckboxRef = checkbox => {
    if (checkbox) {
      checkbox.indeterminate = isIndeterminate;
    }
  };

  return (
    <input
      type="checkbox"
      ref={setCheckboxRef}
      {...otherProps}
    />
  )
}

IndeterminateCheckbox.propTypes = {
  isIndeterminate: PropTypes.bool,
};

IndeterminateCheckbox.defaultProps = {
  isIndeterminate: false
};