import React from 'react'
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import guid from './../shared/guid';
import { multiLineTextToArray } from '../shared/StringUtilities';
import Cartesian from './../shared/Cartesian';
import GeneratorField from './GeneratorField'

class CombinationGenerator extends React.Component {
  static propTypes = {
    onTagsGeneration: PropTypes.func
  }

  constructor(props) {
    super(props);

    let fields = [{ key: guid(), value: "" }];
    fields = this.fieldsWithUpdatedRemoveIconStatus(fields);

    this.state = {
      prefix: "",
      suffix: "",
      separator: "",
      fields: fields
    }
  }

  render() {
    var self = this;

    var fieldNodes = this.state.fields.map(function (field, index) {
      return (
        <GeneratorField
          value={field.value}
          key={field.key}
          index={index}
          handleFieldValueChange={self.handleFieldValueChange}
          handleRemoveField={self.handleRemoveField}
          showFieldRemoveIcon={field.showFieldRemoveIcon}
        />
      );
    });

    return (
      <div>
        <form className="form-horizontal" onSubmit={this.handleSubmit}>
          <fieldset className="content-group no-margin-bottom">
            <legend>
              <span className="text-bold">
                Create tags based on combinations of several values
              </span>
            </legend>

            <div className="form-group">
              <label className="control-label col-md-2 text-right" htmlFor="separator">Separator</label>
              <div className="col-md-1">
                <input
                  type="text"
                  className="form-control"
                  id="separator"
                  name="separator"
                  value={this.state.separator}
                  onChange={this.handleSeparatorChange}
                />
              </div>
            </div>

            <div className="form-group">
              <label className="control-label col-md-2 text-right" htmlFor="prefix">Prefix</label>
              <div className="col-md-2">
                <input
                  type="text"
                  className="form-control"
                  name="prefix"
                  value={this.state.prefix}
                  onChange={this.handlePrefixChange}
                />
              </div>
            </div>

            <div className="form-group">
              <label className="control-label col-md-2 text-right" htmlFor="suffix">Suffix</label>
              <div className="col-md-2">
                <input
                  type="text"
                  className="form-control"
                  name="suffix"
                  value={this.state.suffix}
                  onChange={this.handleSuffixChange}
                />
              </div>
            </div>

            {/* This needs to be in a div since adding new ones can be wonky without this */}
            <div>
              {fieldNodes}
            </div>

            <div className="form-group">
              <div className="col-md-offset-2 col-md-2">
                <a onClick={this.handleAddField}>
                  <i className="icon-plus22 position-left"></i>
                  Add another
                </a>
              </div>
            </div>

            <div className="form-group">
              <div className="col-md-offset-2 col-md-2">
                <input type="submit" className="btn btn-default" value="Generate Combinations" />
                <span className="help-block text-muted">
                  Populates the table below
                </span>
              </div>
            </div>
          </fieldset>

        </form>
      </div>
    );
  }

  handleSeparatorChange = (event) => {
    this.setState({ separator: event.target.value });
  }

  handlePrefixChange = (event) => {
    this.setState({ prefix: event.target.value });
  }

  handleSuffixChange = (event) => {
    this.setState({ suffix: event.target.value });
  }

  // TODO: This should not be a form. It should be handled via a normal click
  handleSubmit = (event) => {
    event.preventDefault();
    var self = this;
    var prefix = "", suffix = "";
    var rawTags = [];

    if (this.state.prefix) {
      prefix = this.state.prefix + this.state.separator;
    }

    if (this.state.suffix) {
      suffix = this.state.separator + this.state.suffix;
    }

    let rawFieldsData = [];
    for (let i = 0; i < self.state.fields.length; i++) {
      let field = self.state.fields[i];
      let value = field.value || "";
      let fieldData = multiLineTextToArray(value);

      // To make sure that an empty combination field doesn't prevent generating tags
      if (fieldData.length > 0) {
        rawFieldsData.push(fieldData);
      }
    }

    if (rawFieldsData.length > 0) {
      rawTags = new Cartesian(rawFieldsData).values();
    }

    rawTags = rawTags.map(function (tag) {
      let joinedTag = tag.join(self.state.separator);
      return prefix + joinedTag + suffix;
    });

    this.props.onTagsGeneration(rawTags);
  }

  handleFieldValueChange = (changedValue, index) => {
    let newFields = update(
      this.state.fields, { [index]: { value: { $set: changedValue }, } }
    );

    this.setState({ fields: newFields });
  }

  handleAddField = () => {
    var newField = { key: guid(), data: [] }
    var fields = this.state.fields;
    fields.push(newField);
    fields = this.fieldsWithUpdatedRemoveIconStatus(fields);
    this.setState({ fields: fields });
  }

  handleRemoveField = (index) => {
    var fields = this.state.fields;
    fields.splice(index, 1);
    fields = this.fieldsWithUpdatedRemoveIconStatus(fields);
    this.setState({ fields: fields });
  }

  fieldsWithUpdatedRemoveIconStatus = (fields) => {
    var updatedfields = fields;

    if (updatedfields.length <= 1) {
      updatedfields[0].showFieldRemoveIcon = false;
    } else {
      updatedfields.map(function (field) {
        field.showFieldRemoveIcon = true;
      });
    }

    return updatedfields;
  }
};

export default CombinationGenerator
