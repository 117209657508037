import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

export default function SimplePortal(props) {
  const resultElement = document.getElementById(props.domId);
  return ReactDOM.createPortal(props.children, resultElement);
}

SimplePortal.propTypes = {
  domId: PropTypes.string.isRequired
}