export default class BulkOperation {
  constructor() {
    this.setup();
  }

  setup() {
    // Submitting the form
    $(".bulk-action a").on("click", function(event) {
      if ($(this).hasClass("disabled")) {
        event.preventDefault();
      } else {
        var $attributeInput = $(event.target).closest('.bulk-action').find(".bulk-attribute");
        $attributeInput.prop('disabled', false);
        $('#bulk-update-form').submit();
        analytics.track("BulkOperation Selected");
      }
    });
  }

  // NOTE: Make sure to bind this function with the parent object
  // to define 'this' in this context
  update(itemIds) {
    this.updateInputModelIdElements(itemIds);
    this.updateButtonStatus(itemIds);
  }

  updateInputModelIdElements(itemIds) {
    let inputModelIdsElements = [];
    for (let itemId of itemIds) {
      let element = "<input value='" + itemId + "' type='hidden' name='bulk_operation[model_ids][]'>"
      inputModelIdsElements.push(element);
    };

    $("#bulk-operation-model-ids").html(inputModelIdsElements.join("\n"));
  }

  updateButtonStatus(itemIds) {
    if (itemIds.length > 0) {
      $(".bulk-operations").toggleClass("disabled", false);
    } else {
      $(".bulk-operations").toggleClass("disabled", true);
    }
  }

};
