import React from 'react'
import PropTypes from 'prop-types'

class Parameter extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    column: PropTypes.object.isRequired,
    baseInputName: PropTypes.string.isRequired
  }

  render () {
    const baseInputNameWithIndex = this.props.baseInputName + "[" + this.props.index + "]";

    return (
      <div>
        <input
          type="hidden"
          name={baseInputNameWithIndex + "[type]"}
          value={this.props.column.type}
        />

        <input
          type="hidden"
          name={baseInputNameWithIndex + "[name]"}
          value={this.props.column.name}
        />

        <div className='col-xs-5'>
          <span className='sorting-parameters'>{this.props.column.full_name} <span className='pl-10 text-muted'>{this.props.column.type}</span></span>
        </div>
      </div>
    )
  }
};

export default Parameter;
