// This reducer takes in a bunch of fields and updates them.

import {
  valueWithoutPrefixSuffix,
  findFieldForFieldId
} from "./fieldHelpers"

export const UPDATE_FIELD_VALUE = 'UPDATE_FIELD_VALUE'
export const UPDATE_MULTIPLE_FIELD_VALUES = 'UPDATE_MULTIPLE_FIELD_VALUES'
export const REFRESH_FIELD_VISIBILITY = 'REFRESH_FIELD_VISIBILITY'

export const fieldsReducer = (fields, action) => {
  let updatedFields;

  switch (action.type) {
    case UPDATE_FIELD_VALUE:
      updatedFields = fields.map(field => {
        if (field.id !== action.payload.id || field.type == 'ConstantField') return field
        return { ...field, current_value: action.payload.value }
      })

      // If the value did not change, stop here to avoid hiding all the grandchildren already visible
      const currentField = findFieldForFieldId(action.payload.id, updatedFields) // Get the updated field
      showChildFieldForValue(updatedFields, currentField, action.payload.value); // These can be updated in place
      return updatedFields;

    case UPDATE_MULTIPLE_FIELD_VALUES:
      return fields.map(field => {
        if (field.id !== action.payload.id || field.type == 'ConstantField') return field
        return { ...field, multiple_values: action.payload.values }
      })

    case REFRESH_FIELD_VISIBILITY:
      // Make the fields with no parent option visible
      updatedFields = fields.map(field => {
        const visible = field.parent_option_value_id === null // This is also done in FieldsMapPopulator
        return { ...field, visible: visible }
      })

      // For each field, set the visiblity on their children based on its value
      updatedFields.map(field => {
        showChildFieldForValue(updatedFields, field, field.current_value);
      })
      return updatedFields;

    default:
      throw new Error();
  }
}

// NOTE: Eventually function should be removed
// Temporary to populate from separator
export const bulkUpdateFields = (fields, values) => {
  let updatedFields = JSON.parse(JSON.stringify(fields));

  for (var i = 0, l = values.length; i < l; i++) {
    var field = visibleFields(updatedFields)[i];
    if (field) {
      var value = valueWithoutPrefixSuffix(values[i], field.prefix, field.suffix);
      if (field.type !== 'ConstantField') {
        field.current_value = value;
      }
      showChildFieldForValue(updatedFields, field, value); // These can be updated in place
    }
  }

  return updatedFields;
}

//-------^ Should be removed eventually ^---------

const visibleFields = (fields) => {
  return fields.filter(field => field.visible);
}

const hideAllChildFields = (parentField, fields) => {
  var childFields = findAllChildFields(parentField, fields);

  childFields.forEach(function (field) {
    hideField(field, fields)
  });
}

const hideField = (currentField, fields) => {
  // TODO: If a field becomes invisible, all of its descendents should also become invisible
  hideAllChildFields(currentField, fields);
  currentField.visible = false // Mutating it is fine here since these are copies fields
}

const findAllChildFields = (parentField, fields) => {
  let allChildFields = [];

  if (parentField.type == "OptionField") {
    parentField.option_values.forEach(function (optionValue) {
      const childFields = findChildFieldsForParentOptionValueId(optionValue.id, fields);
      childFields.forEach(function (childField) {
        allChildFields.push(childField);
      });
    });
  }

  return allChildFields;
}

const findChildFieldsForParentOptionValueId = (optionValueId, fields) => {
  var childFields = fields.filter(function (field) {
    return (field.parent_option_value_id === optionValueId);
  });

  return childFields;
}

const optionValueIdForName = (field, optionName) => {
  var optionValues = field.option_values.filter(function (optionValue) {
    return optionValue.name === optionName;
  });

  if (optionValues[0]) {
    return optionValues[0].id;
  } else {
    return null;
  }
}

// Show relevant child field for field value
const showChildFieldForValue = (fields, currentField, value) => {
  if (currentField.type == "OptionField") {
    const optionValueId = optionValueIdForName(currentField, value);
    hideAllChildFields(currentField, fields);

    const childFields = findChildFieldsForParentOptionValueId(optionValueId, fields);
    childFields.forEach(function (childField) {
      showField(childField, fields);
    });
  }
}

const showField = (currentField) => {
  currentField.visible = true
}
