import React, { useState } from "react";
import PropTypes from 'prop-types';

export default function ApplyTagButton(props) {
  let isCustomParameter = props.isCustomParameter || false
  let isInfoField = props.isInfoField || false

  const onApplyClick = () => {
    props.onApplyFieldsClick(props.fieldName, props.inputValues, isCustomParameter, isInfoField, false)
  }

  const onApplyOverrideClick = () => {
    props.onApplyFieldsClick(props.fieldName, props.inputValues, isCustomParameter, isInfoField, true)
  }

  const onApplyPushLabelClick = () => {
    props.onApplyPushLabelClick(props.inputValues)
  }

  // Note: If the field is among `isStringValue` or `allowMultipleValues` allow length >= 1.
  //       If the field is among utm Parameters then allow length == 1
  const isStringValue = props.isNoteField || isCustomParameter || isInfoField
  const allowMultipleValues = props.isLabelField

  const disableStatus = (isStringValue || allowMultipleValues) ? !props.inputValues?.length : props.inputValues?.length != 1

  let pushLabelsButton;
  if(props.isLabelField){
    pushLabelsButton = (
      <li>
        <a
          onClick={onApplyPushLabelClick}>
          Apply to Add new
        </a>
      </li>
    )
  }

  let disabledMessage;
  if(props.isNoteField || props.isLabelField){
    disabledMessage = "Cannot Apply empty value"
  }else{
    disabledMessage = "Only Single value can be applied"
  }

  let disabledMessageNode = (
    <a className="tool-tip position-right"
          data-toggle="tooltip"
          data-placement="top"
          data-trigger="hover"
          title={disabledMessage}>
      <i className="icon-info3 text-muted text-size-small"></i>
    </a>
  )

  return (
    <div className='btn-group heading-btn'>
      <button
        type='button'
        className='btn border-teal text-white btn-flat bg-teal'
        onClick={onApplyClick}
        disabled= {disableStatus}>
          Apply
      </button>
      <button 
        type='button'
        className='btn text-white btn-flat dropdown-toggle bg-teal'
        aria-expanded='false' data-toggle='dropdown'
        disabled= {disableStatus}>
        <span className="caret"></span>
      </button>
      <ul className="dropdown-menu dropdown-menu-right">
        <li>
          <a
            onClick={onApplyOverrideClick}>
            Apply to Override
          </a>
        </li>
        {pushLabelsButton}
      </ul>
      {disabledMessageNode}
    </div>
  )
}

ApplyTagButton.propTypes = {
  inputValues: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  fieldName: PropTypes.string.isRequired,
  onApplyFieldsClick: PropTypes.func.isRequired,
  isCustomParameter: PropTypes.bool,
  isInfoField: PropTypes.bool,
  isLabelField: PropTypes.bool,
  isNoteField: PropTypes.bool,
  onApplyPushLabelClick: PropTypes.func
}