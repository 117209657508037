import React from 'react';
import PropTypes from 'prop-types'
import Select2 from 'react-select2-wrapper';

class LabelSelect extends React.PureComponent {
  static propTypes = {
    labelNames: PropTypes.array,
    availableLabels: PropTypes.array,
    inputName: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  // NOTE: When populating all the parameter sets (utms, info fields, labels, etc.) using
  // the main combination/common fields, any new labels (that are not part of the available labels)
  // are not applied for all the parameter sets. This is because those need to be added to the
  // "list". If those are added to the list on render, it can create problem in common label selector.
  // So not handling that case for now.
  tagData = (list) => {
    let self = this;

    let data = list.map(function(item, index) {
      return {id: item.name, text: item.name, title: item.description};
    });

    return data;
  }

  render () {
    let node = "";

    let selectOptions = { width: '100%',
                          minimumResultsForSearch: Infinity,
                          tags: true,
                          placeholder: "Select/add labels" }

    node = <Select2
             multiple
             value={this.props.labelNames}
             defaultValue={this.props.labelNames}
             data={this.tagData(this.props.availableLabels)}
             name={this.props.inputName}
             onSelect={this.handleChange}
             onUnselect={this.handleChange}
             options={selectOptions}
          />
    return node;
  }

  // Using onUnselect to avoid using onChange bug in Select2 wrapper
  // https://github.com/rkit/react-select2-wrapper/issues/63
  handleChange = (event) => {
    let labelNames = [];
    for(let option of event.target.selectedOptions) {
      labelNames.push(option.value);
    }
    this.props.handleChange(labelNames);
  }
};

export default LabelSelect;
