import React from 'react'
import PropTypes from 'prop-types'

class Tag extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    paramBase: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    initialName: PropTypes.string,
    initialTag: PropTypes.string,
    initialDescription: PropTypes.string,
    initialErrors: PropTypes.object,
    showTagRemoveIcon: PropTypes.bool.isRequired,
    handleRemoveTag: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);

    this.handleRemove = this.handleRemove.bind(this);

    this.state = {
      tag: this.props.initialTag || "",
      description: this.props.initialDescription || "",
      errors: this.props.initialErrors || {}
    }
  }

  render () {
    var tagErrorNode, tagRemoveIcon;

    if (this.state.errors.tag && this.state.errors.tag.length > 0) {
      tagErrorNode = <span className="text-error">{this.state.errors.tag[0]}</span>
    }

    if (this.props.showTagRemoveIcon) {
      tagRemoveIcon = (
        <a title="Remove"
           className="text-danger"
           tabIndex="-1"
           onClick={this.handleRemove}>
          <i className="icon-cross3" tabIndex="-1"></i>
        </a>
      )
    }

    var tagFieldName = this.props.paramBase + "[][tag]"
    var descriptionFieldName = this.props.paramBase + "[][description]"

    return(
      <tr>
        <td>
          <div className="col-md-4">
            <input
              className="form-control"
              type="text"
              required="required"
              name={tagFieldName}
              value={this.state.tag}
              onChange={this.handleTagChange}
            />
            {tagErrorNode}
          </div>
        </td>
        <td>
          <input
            type="text"
            className="form-control"
            name={descriptionFieldName}
            value={this.state.description}
            onChange={this.handleDescriptionChange}
          />
        </td>
        <td>
          {tagRemoveIcon}
        </td>
      </tr>
    );
  }

  handleTagChange = (event) => {
    this.setState({tag: event.target.value, errors: {}});
  }

  handleDescriptionChange = (event) => {
    this.setState({description: event.target.value});
  }

  handleRemove = () => {
    this.props.handleRemoveTag(this.props.index);
  }
};

export default Tag
