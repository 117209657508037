import React from 'react'
import PropTypes from 'prop-types'

class OptionValue extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    optionValue: PropTypes.object.isRequired,
    onRemove: PropTypes.func.isRequired,
    onNameChange: PropTypes.func.isRequired,
    onDescriptionChange: PropTypes.func.isRequired
  }

  onRemove = () => {
    this.props.onRemove(this.props.index);
  }

  onNameChange = (event) => {
    var value = event.target.value;
    this.props.onNameChange(this.props.index, value);
  }

  onDescriptionChange = (event) => {
    var value = event.target.value;
    this.props.onDescriptionChange(this.props.index, value);
  }

  render() {
    const baseInputName = "field[option_values_attributes][" + this.props.index + "]";

    let dependentFieldsNode;

    if (this.props.optionValue.child_fields && this.props.optionValue.child_fields.length > 0) {
      dependentFieldsNode = (
        <a className="tool-tip"
          data-toggle="tooltip"
          data-placement="top"
          data-trigger="hover"
          data-original-title="This option value has one or more dependent fields. Deleting it will remove this dependency.">
          <i className="icon-tree7 pt-10 text-muted"></i>
        </a>
      )
    }

    let removeIconNode = (
      <a title="Remove"
        className="text-danger"
        tabIndex="-1"
        onClick={this.onRemove}>
        <i className="icon-cross3" tabIndex="-1"></i>
      </a>
    )

    let destroyInputNode;
    if (this.props.optionValue.markedForDistruction) {
      destroyInputNode = (
        <input
          type="hidden"
          name={baseInputName + "[_destroy]"}
          value="1"
        />
      )
    }

    return (
      <div>
        <input
          type="hidden"
          name={baseInputName + "[id]"}
          defaultValue={this.props.optionValue.id}
        />

        <input
          type="hidden"
          name={baseInputName + "[position]"}
          defaultValue={this.props.optionValue.position}
        />

        {destroyInputNode}

        <div className={`option-name col-xs-3 ${this.props.optionValue.duplicate ? "has-error" : ""}`}>
          <input
            className="form-control"
            type="text"
            placeholder="Value"
            name={baseInputName + "[name]"}
            value={this.props.optionValue.name}
            onChange={this.onNameChange}
          />
        </div>
        <div className='col-xs-6'>
          <input
            className="form-control"
            type="text"
            placeholder="Description"
            name={baseInputName + "[description]"}
            value={this.props.optionValue.description}
            onChange={this.onDescriptionChange}
          />
        </div>
        <div className='col-xs-1' style={{ width: "30px" }}>
          {dependentFieldsNode}
        </div>
        <div className='col-xs-1 pt-5'>
          {removeIconNode}
        </div>
      </div>
    )
  }
};

export default OptionValue;
