import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle
} from 'react';

import styled, { css, createGlobalStyle } from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import moment from 'moment';

const DatePickerWrapperStyles = createGlobalStyle`
  .grid-date-cell-editor-popup {
    .react-datepicker {
      font-family: "Roboto", Helvetica Neue, Helvetica, Arial, sans-serif;
      font-size: 12px;
    }

    .react-datepicker__day-name, 
    .react-datepicker__day, 
    .react-datepicker__time-name {
      width: 20px;
      margin: 5px;
      line-height: 1;
      padding: 3px;
    }

    .react-datepicker__current-month, 
    .react-datepicker-time__header, 
    .react-datepicker-year-header {
      font-size: 14px;
      line-height: 1;
      color: #333333;
      font-weight: 400;
    }
    .react-datepicker__day-names {
      margin-top: 10px;
    }
  }

  .grid-date-cell-editor-input {
    input[type="text"] {
      width: 100%;
      height: 27px;
      padding-left: 4px;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      border-width: 1px;
      border-style: solid;
      border-color: #95a5a6;
    }
    input[type="text"]:focus {
      outline: none;
      box-shadow: 0 0 2px 1px #719ECE;
      border-color: #719ECE;
    }
  }
`;

// NOTE: Date string is parsed via moment.js. This format is not compatible with DatePicker.
// Moment is compatibale with date formats stored in the database. Other datetime libariers
// do not support all date notations like YYYY.
// Check that all the date formats are supported the same. Some libraries like date-fns used by react-datepicker 
// don't support YYYY easily. They need yyyy. Similarly, D and DD represent the day of the year and 
// not day of the month in date-fns.
// Pass the actual date object to DatePicker and not a string.
const EDITOR_DATE_FORMAT = 'YYYY/MM/DD';

export default forwardRef((props, ref) => {
  const cellObject = props.value; // is a hash of { value: '1221', lastSelectedDate: '2021/12/31' }
  const [date, setDate] = useState(() => {
    if (cellObject.lastSelectedDate) {
      return moment(cellObject.lastSelectedDate, EDITOR_DATE_FORMAT).toDate();
    } else if (cellObject.value && cellObject.value.length > 0) {
      return moment(cellObject.value, props.dateFormat).toDate();
    } else {
      return moment().toDate();
    }
  });

  const refDatePicker = useRef();
  const [editing, setEditing] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      refDatePicker.current.setOpen(true)
    });
  }, []);

  // TODO: This is common with other editors
  useEffect(() => {
    if (!editing) {
      props.api.stopEditing();
    }
  }, [editing]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        const value = moment(date).format(props.dateFormat);
        const lastSelectedDate = moment(date).format(EDITOR_DATE_FORMAT);
        return { value: value, lastSelectedDate: lastSelectedDate };
      }
    };
  });

  const onChange = selectedDate => {
    setDate(selectedDate);
    setEditing(false);
  };

  // TODO: Doesn't seem to work reliably.
  // The first click (without making any changes) is not recognized.
  // const handleOnBlur = () => {
  //   setEditing(false);
  // }

  return (
    <div>
      <DatePicker
        ref={refDatePicker}
        portalId={props.portalId}
        popperClassName="grid-date-cell-editor-popup"
        wrapperClassName="grid-date-cell-editor-input"
        selected={date}
        onChange={onChange}
        // onBlur={handleOnBlur}
        showWeekNumbers
        fixedHeight
      />
      <DatePickerWrapperStyles />
    </div>
  );
});