import React, { memo } from 'react';
import EditableCell from './EditableCell';

export default props => {
  const cellObject = props.value;

  const valueNode = cellObject.map(item => {
    const color = item.color ? item.color : '#888';
    const style = {
      borderColor: color,
      backgroundColor: color,
      padding: '0px 2px',
      marginRight: '3px'
    };

    return <span key={item.value} className="badge" style={style}>{item.value}</span>
  })

  return (
    <EditableCell gridProps={props} wrapperClassName='grid-dropdown-cell-renderer' iconClassName='icon-pencil3 text-size-small'>
      {valueNode}
    </EditableCell>
  );
};
