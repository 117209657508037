import React from 'react';
import PropTypes from 'prop-types';
import Field from './Field';

export default function FieldSet({
  fields,
  showLabel,
  updateFieldValue,
  updateMultipleFieldValues,
  prohibitedCharacters,
  allowMultipleFieldValues,
  showConstantField = true
}) {

  return (
    <div>
      {fields.map(field => (
        <Field
          key={field.id}
          field={field}
          showLabel={showLabel}
          initialValue={field.current_value}
          updateFieldValue={updateFieldValue}
          updateMultipleFieldValues={updateMultipleFieldValues}
          prohibitedCharacters={prohibitedCharacters}
          allowMultipleFieldValues={allowMultipleFieldValues}
          showConstantField={showConstantField}
        />
      ))
      }
    </div>
  )
}

FieldSet.propTypes = {
  fields: PropTypes.array.isRequired,
  showLabel: PropTypes.bool.isRequired,
  updateFieldValue: PropTypes.func.isRequired,
  updateMultipleFieldValues: PropTypes.func.isRequired,
  prohibitedCharacters: PropTypes.array.isRequired,
  allowMultipleFieldValues: PropTypes.bool.isRequired,
  showConstantField: PropTypes.bool
}