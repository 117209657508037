import React from 'react'
import PropTypes from 'prop-types';

export default function FieldLabel({ field, showLabel }) {
  let labelNode;
  let className;

  if (showLabel) {
    labelNode = (
      <label>
        {field.name}
        {field.required &&
          <span className="text-danger"> *</span>
        }
      </label>
    )
    className = "display-inline-block";
  }

  return (
    <div className={className}>
      {labelNode}
    </div>
  )
}

FieldLabel.propTypes = {
  field: PropTypes.object.isRequired,
  showLabel: PropTypes.bool.isRequired
}