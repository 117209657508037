import React from 'react';
import PropTypes from 'prop-types';
import Tag from './Tag';

class TagList extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string,
    paramBase: PropTypes.string.isRequired,
    tags: PropTypes.array,
    handleRemoveTag: PropTypes.func
  }

  render () {
    var self = this;
    var tagNodes = this.props.tags.map(function(tag, index) {
      return (
        <Tag
          key={tag.key}
          type={self.props.type}
          paramBase={self.props.paramBase}
          index={index}
          initialName={tag.name}
          initialTag={tag.tag}
          initialDescription={tag.description}
          initialErrors={tag.errors}
          showTagRemoveIcon={tag.showTagRemoveIcon}
          handleRemoveTag={self.props.handleRemoveTag}
        />
      );
    });

    return (
      <table className='table'>
        <thead>
          <tr>
            <th>{this.props.type} Name</th>
            <th>Notes</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tagNodes}
        </tbody>
      </table>
    );
  }

};

export default TagList;
