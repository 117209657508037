import React from 'react'
import PropTypes from 'prop-types'
import Select2 from 'react-select2-wrapper';

class Column extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    column: PropTypes.object.isRequired,
    baseInputName: PropTypes.string.isRequired,
    availableColumns: PropTypes.array.isRequired,
    handleNameChange: PropTypes.func.isRequired,
    handleHeaderChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    showRemoveIcon: PropTypes.bool.isRequired
  }

  onRemove = () => {
    this.props.onRemove(this.props.index);
  }

  handleNameChange = (event) => {
    this.props.handleNameChange(this.props.index, event.target.value);
  }

  handleHeaderChange = (event) => {
    this.props.handleHeaderChange(this.props.index, event.target.value);
  }

  render () {
    let availableColumns = this.props.availableColumns.map(function(column, index) {
      return {
        id: column.id,
        text: column.name,
        title: column.columnType,
        'data-description': column.columnType
      };
    });

    const baseInputNameWithIndex = this.props.baseInputName + "[" + this.props.index + "]";

    let removeIconNode;

    if (this.props.showRemoveIcon) {
      removeIconNode = (
        <a title="Remove"
           className="text-danger"
           tabIndex="-1"
           onClick={this.onRemove}>
          <i className="icon-cross3" tabIndex="-1"></i>
        </a>
      )
    }

    return (
      <div>
        <input
          type="hidden"
          name={baseInputNameWithIndex + "[column_type]"}
          value={this.props.column.columnType}
        />

        <input
          type="hidden"
          name={baseInputNameWithIndex + "[name]"}
          value={this.props.column.name}
        />

        <div className='col-xs-5'>
          <Select2
            value={this.props.column.id}
            data={availableColumns}
            onSelect={this.handleNameChange}
            required={true}
            className='classification-columns'
            options={
              {
                width: '100%',
                placeholder: "Select a parameter or info field",
                templateResult: terminus.select2DescriptionTemplate,
                templateSelection: terminus.select2DescriptionTemplate
              }
            }
          />
        </div>

        <div className='col-xs-5'>
          <input
            type="text"
            name={baseInputNameWithIndex + "[header]"}
            value={this.props.column.header}
            onChange={this.handleHeaderChange}
            placeholder="Column Heading"
            required={true}
            className='form-control'
          />
        </div>

        <div className='col-xs-1 pt-5'>
          {removeIconNode}
        </div>
      </div>
    )
  }
};

export default Column;
