import React from 'react';
import PropTypes from 'prop-types';
import PermissionFlag from './PermissionFlag';
import IndeterminateCheckbox from '../shared/IndeterminateCheckbox';

export default function ParamsPermissionFlags({ permission, inputNamePrefix, updateFlag }) {
  const paramFlags = [
    { flagName: 'write_campaigns', flagValue: permission.write_campaigns, name: "Campaigns" },
    { flagName: 'write_media', flagValue: permission.write_media, name: "Mediums" },
    { flagName: 'write_sources', flagValue: permission.write_sources, name: "Sources" },
    { flagName: 'write_contents', flagValue: permission.write_contents, name: "Contents" },
    { flagName: 'write_terms', flagValue: permission.write_terms, name: "Terms" },
    { flagName: 'write_custom_values', flagValue: permission.write_custom_values, name: "Custom Parameters" }
  ]

  const allSelected = paramFlags.every((paramFlag) => paramFlag.flagValue)
  const noneSelected = paramFlags.every((paramFlag) => !paramFlag.flagValue)
  const isIndeterminate = !allSelected && !noneSelected;

  const handleGroupValueChange = (e) => {
    paramFlags.forEach((paramFlag) => updateFlag(paramFlag.flagName, e.target.checked))
  }

  return (
    <div className="btn-group">
      <div className="btn btn-default btn-sm">
        <IndeterminateCheckbox isIndeterminate={isIndeterminate} checked={allSelected} onChange={handleGroupValueChange} className="no-margin" />
      </div>
      <button aria-expanded="false" className="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" type="button">
        <span className="caret"></span>
      </button>
      <ul className="dropdown-menu dropdown-menu-xs">
        {paramFlags.map((paramFlag) => (
          <li key={paramFlag.flagName} className="check-item">
            <PermissionFlag
              inputNamePrefix={inputNamePrefix}
              flagName={paramFlag.flagName}
              flagValue={paramFlag.flagValue}
              updateFlag={updateFlag}
              name={paramFlag.name}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

ParamsPermissionFlags.propTypes = {
  permission: PropTypes.any.isRequired,
  inputNamePrefix: PropTypes.string.isRequired,
  updateFlag: PropTypes.func.isRequired,
};
