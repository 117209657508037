import React from 'react';
import PropTypes from 'prop-types';

export default function PermissionFlag({ inputNamePrefix, flagName, flagValue = false, updateFlag, name }) {
  const inputName = inputNamePrefix + "[" + flagName + "]"

  const handleFlag = () => {
    updateFlag(flagName, !flagValue)
  }

  return (
    <a>
      <div className="checkbox no-margin">
        <label>
          {!flagValue &&
            <input name={inputName} type="hidden" value="0" />
          }
          <input name={inputName} type="checkbox" value="1"
            checked={flagValue} onChange={handleFlag} />
          {name}
        </label>
      </div>
    </a>
  );
}

PermissionFlag.propTypes = {
  inputNamePrefix: PropTypes.string.isRequired,
  flagName: PropTypes.string.isRequired,
  flagValue: PropTypes.bool,
  updateFlag: PropTypes.func.isRequired,
  name: PropTypes.string
};
