import React from 'react';
import PropTypes from 'prop-types'
import Select2 from 'react-select2-wrapper';

class MultipleValueInput extends React.PureComponent {
  static propTypes = {
    currentValues: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    parameterName: PropTypes.string.isRequired,
    availableValues: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  // NOTE: This is duplicate in GenericSelect
  tagData = (list, currentValue) => {
    let self = this;
    let valueIsInOptions = false;

    let data = list.map(function(item, index) {
      return {id: item.tag, text: item.tag, title: item.description};
    });

    // Select 2 needs any new values set on element to be present in items to show it.
    if (currentValue) {
      for (let item of list) {
        if ( item.tag === currentValue) {
          valueIsInOptions = true;
        }
      }
      if (!valueIsInOptions) {
        let newItem = {id: currentValue, text: currentValue, title: ''}
        data.push(newItem);
      }
    }

    let empty = [{id: null, text: null, title: null}]
    return empty.concat(data);
  }

  render () {
    let node = "";

    let selectOptions = { width: '100%',
                          minimumResultsForSearch: Infinity,
                          tags: true,
                          tokenSeparators: [","],
                          closeOnSelect: true,
                          placeholder: "Select/add " + this.props.label }

    node = <Select2
             multiple
             value={this.props.currentValues}
             defaultValue={this.props.currentValues}
             data={this.tagData(this.props.availableValues)}
             name={this.props.inputName}
             onSelect={this.handleChange}
             onUnselect={this.handleChange}
             options={selectOptions}
          />
    return node;
  }

  // Using onUnselect to avoid using onChange bug in Select2 wrapper
  // https://github.com/rkit/react-select2-wrapper/issues/63
  handleChange = (event) => {
    let values = [];
    for(let option of event.target.selectedOptions) {
      values.push(option.value);
    }
    this.props.handleChange(this.props.parameterName, values);
  }
};

export default MultipleValueInput;
