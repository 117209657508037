import React from 'react';
import PropTypes from 'prop-types';
import { Droppable } from 'react-beautiful-dnd';
import Field from './Field';

export default function FieldsColumn({
  columnId,
  title,
  subtitle = '',
  fields,
  errorFieldIds = [],
  styleType,
  populateInputs = false,
  submitButton = '',
  showFieldEditLink
}) {
  return (
    <div className={`panel panel-default border-lg border-${styleType}`}>
      <div className="panel-heading">
        <h6 className="panel-title">
          <span className="text-semibold">{title}</span>
          <span className='text-size-small text-muted ml-5'>{subtitle}</span>
        </h6>
        {submitButton}
      </div>

      <div className="panel-body p-10 scrollable">
        <Droppable droppableId={columnId} >
          {(provided) => (
            <ul className="list-unstyled" style={{ minHeight: '80px' }} ref={provided.innerRef} {...provided.droppableProps}>
              { fields.map((field, index) => (
                <Field
                  key={field.id}
                  field={field}
                  index={index}
                  populateInputs={populateInputs}
                  error={errorFieldIds.includes(field.id)}
                  showFieldEditLink={showFieldEditLink} />
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </div>
    </div>
  );
}

FieldsColumn.propTypes = {
  columnId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.any).isRequired,
  errorFieldIds: PropTypes.arrayOf(PropTypes.string),
  styleType: PropTypes.string.isRequired,
  populateInputs: PropTypes.bool,
  submitButton: PropTypes.object,
  showFieldEditLink: PropTypes.bool.isRequired
};
