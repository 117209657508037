export default class BulkClone {
  constructor() {
    this.setup();
  }

  setup() {
    let self = this;
    self.links = $(".bulk-clone-link");

    // Save the original href on each link to reuse as href gets updated
    // NOTE: This can potentially be done in the view, but this seems easy enough
    for (let link of self.links) {
      link.setAttribute('data-original-href', link.href);
    };
  }

  // NOTE: Make sure to bind this function with the parent object
  // to define 'this' in this context
  update(itemIds) {
    this.updateBulkCloneLinks(itemIds);
    this.updateButtonStatus(itemIds);
  }

  updateBulkCloneLinks(itemIds) {
    let self = this;

    for (let link of self.links) {
      let originalHref = link.getAttribute('data-original-href');
      let href = originalHref;

      let itemCount = "";

      if (itemIds.length > 0) {
        let url = new URL(originalHref);
        let searchParams = new URLSearchParams(url.search);

        // Remove any existing search parameters
        searchParams.forEach(function(value, key) {
          if (key.match(/ids\[.*\]/)) {
            searchParams.delete(key);
          }
        });

        // Append all the item ids in the query parameter
        for (let itemId of itemIds) {
          searchParams.append('ids[]', itemId);
        };

        url.search = searchParams.toString();
        href = url.toString();
        itemCount = "(" + itemIds.length + ")";
      }

      link.href = href;
      $(".bulk-clone-count").text(itemCount);
    }
  }

  updateButtonStatus(itemIds) {
    if (itemIds.length > 0) {
      $(".bulk-clone-operation").toggleClass("disabled", false);
    } else {
      $(".bulk-clone-operation").toggleClass("disabled", true);
    }
  }
};
