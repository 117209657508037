import React from 'react';
import PropTypes from 'prop-types';

export default function Description({ field }) {
  return (
    <div>
      {field.show_description &&
        <span className="help-block no-margin-bottom">{field.description}</span>
      }
    </div>
  )

}

Description.propTypes = {
  field: PropTypes.object.isRequired
}