export default class ItemSelector {
  constructor({callbacks, checkItemClass='.check-item', checkAllClass='.checkall', itemIdDataAttr='item-id'}) {
    this.checkItemClass = checkItemClass;
    this.checkAllClass = checkAllClass;
    this.itemIdDataAttr = itemIdDataAttr;
    this.callbacks = callbacks;
    this.setup();
  }

  setup() {
    let self = this;

    $(self.checkItemClass).on('click', function (event) {
      event.stopPropagation();
      self.updateCheckAll();
    });

    $(self.checkAllClass).on("click", function(event) {
      var checked = $(this).is(':checked');

      $(self.checkItemClass).each(function(index) {
        $(this).prop('checked', checked);
      });
      self.updateItemIds();
    })
  }

  updateItemIds() {
    let self = this;
    let itemIds = [];
    let $checkedItems = $(self.checkItemClass + ":checked");
    $checkedItems.each(function(index) {
      let itemId = $(this).data(self.itemIdDataAttr);
      itemIds.push(itemId);
    });
    self.callCallbacks(itemIds);
  }

  callCallbacks(itemIds) {
    for (let callback of this.callbacks) {
      callback(itemIds);
    }
  }

  updateCheckAll() {
    let self = this;

    let $checkboxes = $(this.checkItemClass);
    let $checkall = $(this.checkAllClass);

    let checkedCount = $(self.checkItemClass + ":checked").length;
    $checkall.prop('checked', (checkedCount > 0));
    let indeterminate = checkedCount > 0 && checkedCount < $checkboxes.length;
    $checkall.prop('indeterminate', indeterminate);
    this.updateItemIds();
  };
};
