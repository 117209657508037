import React from 'react'
import PropTypes from 'prop-types'

class GeneratorField extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    value: PropTypes.string,
    handleFieldValueChange: PropTypes.func.isRequired,
    handleRemoveField: PropTypes.func.isRequired,
    showFieldRemoveIcon: PropTypes.bool.isRequired
  }

  render () {
    var fieldRemoveIcon;

    if (this.props.showFieldRemoveIcon) {
      fieldRemoveIcon = (
        <div className="col-md-1">
          <a title="Remove"
             className="text-danger pl-10"
             tabIndex="-1"
             onClick={this.handleRemove}>
            <i className="icon-cross3" tabIndex="-1"></i>
          </a>
        </div>
      )
    }

    return (
      <div className="form-group">
        <label className="control-label col-md-2 text-right" htmlFor="suffix">
          <a className="tool-tip"
             data-toggle="tooltip"
             data-placement="top"
             data-trigger="hover"
             title="Enter one value on each line">
            <i className="icon-info22 text-default"></i>
          </a>
          &nbsp;
          Combination Values {this.props.index + 1}
        </label>
        <div className="col-md-6">
          <textarea
            className="form-control"
            value={this.props.value}
            onChange={this.handleChange}
            placeholder="One value per line"
          />
        </div>
        {fieldRemoveIcon}
      </div>
    )
  }

  handleChange = (event) => {
    let value = event.target.value;
    this.props.handleFieldValueChange(value, this.props.index);
  }

  handleRemove = () => {
    this.props.handleRemoveField(this.props.index);
  }
};

export default GeneratorField
