/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// As per https://github.com/rails/webpacker/blob/master/docs/es6.md and
// https://babeljs.io/docs/en/babel-polyfill
import "core-js/stable";
import "regenerator-runtime/runtime";
import { LicenseManager } from "@ag-grid-enterprise/core";

window.terminus = window.terminus || {}
window.env = window.env || {}

// This is required to make jQuery available to non-webpack scripts
import $ from "expose-loader?exposes=$,jQuery!jquery"
// import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// Fix CVE: https://github.com/advisories/GHSA-gxr4-xjj5-5px2
window.jQuery.htmlPrefilter = function (html) {
  return html;
};

// Set AG Grid license key
LicenseManager.setLicenseKey(window.env.agLicenseKey);

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)

// Make some components globally available
import ItemSelector from '../components/ItemSelector';
terminus.ItemSelector = ItemSelector;

import BulkOperation from '../components/BulkOperation';
terminus.BulkOperation = BulkOperation;

import ExportQueryUpdater from '../components/ExportQueryUpdater';
terminus.ExportQueryUpdater = ExportQueryUpdater;

import BulkClone from '../components/BulkClone';
terminus.BulkClone = BulkClone;
