import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import CsrfToken from './shared/CsrfToken';
import guid from './shared/guid';
import CombinationGenerator from './multiTagCreateForm/CombinationGenerator';
import TagList from './multiTagCreateForm/TagList';

class MultiTagCreateForm extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    paramBase: PropTypes.string.isRequired,
    initialTags: PropTypes.array,
    submitUrl: PropTypes.string.isRequired,
    cancelUrl: PropTypes.string.isRequired,
    csrf: PropTypes.objectOf(PropTypes.string).isRequired,
    maxTagsCount: PropTypes.number.isRequired
  }

  constructor(props) {
    super(props);

    this.handleRemoveTag = this.handleRemoveTag.bind(this);
    this.handleAddTag = this.handleAddTag.bind(this);

    var tags = [{}, {}, {}];

    if (this.props.initialTags.length > 0) {
      tags = this.props.initialTags;
    }

    tags.map(function(tag) {
      tag.key = guid();
    });

    tags = this.tagsWithUpdatedRemoveIconStatus(tags);
    this.state = {
      tags: tags,
      showCombinationGenerator: false,
      submitDisabled: false,
      tooManyTags: false
    }
  }

  render () {
    var combinationGeneratorNode, combinationToggleLink;
    var tooManyTagsErrorNode;

    if (this.state.showCombinationGenerator) {
      combinationGeneratorNode = <CombinationGenerator onTagsGeneration={this.handleGeneratedTags}/>
      combinationToggleLink = "Hide Advanced Options";
    } else {
      combinationToggleLink = "Show Advanced Options";
    }

    if (this.state.tooManyTags) {
      tooManyTagsErrorNode = (
        <span className="text-danger">
          Maximum of {this.props.maxTagsCount} tags can be created at a time. Current tag count: <strong>{this.state.tags.length}</strong>
        </span>
      );
    }

    return (
      <div>
        <div className="mb-10">
          <a onClick={this.handleCombinationToggle}>
            {combinationToggleLink}
          </a>
        </div>

        {combinationGeneratorNode}

        <form action={this.props.submitUrl} acceptCharset="UTF-8" method="post"
          onSubmit={this.handleSubmit}>
          <CsrfToken csrf={this.props.csrf}/>

          {tooManyTagsErrorNode}

          <fieldset className="content-group">
            <TagList
              type={this.props.type}
              paramBase={this.props.paramBase}
              tags={this.state.tags}
              handleRemoveTag={this.handleRemoveTag}
            />
          </fieldset>

          <div className="mb-10">
            <a onClick={this.handleAddTag}>
              <i className="icon-plus22 position-left"></i>
              Add another
            </a>
          </div>

          <div className="mb-20">
            {tooManyTagsErrorNode}
          </div>

          <div className="form-group">
            <button name="button" type="submit" disabled={this.state.submitDisabled} className="btn btn-primary">Save</button>
            &nbsp;
            &nbsp;
            <a href={this.props.cancelUrl}>Cancel</a>
          </div>
          <span className="text-muted">{this.state.tags.length} tags will be created</span>
        </form>

      </div>
    );
  }

  handleCombinationToggle = (event) => {
    this.setState({showCombinationGenerator: !this.state.showCombinationGenerator});
  }

  handleRemoveTag = (index) => {
    let newTags = update(this.state.tags, { $splice: [[index, 1]]});
    let tags = this.tagsWithUpdatedRemoveIconStatus(newTags);
    this.setState({tags: tags}, this.checkTagsLength);
  }

  handleAddTag = () => {
    let newTag = { key: guid() }
    let newTags = update(this.state.tags, { $push: [newTag]});
    let tags = this.tagsWithUpdatedRemoveIconStatus(newTags);
    this.setState({tags: tags}, this.checkTagsLength);
  }

  tagsWithUpdatedRemoveIconStatus = (tags) => {
    var updatedTags = tags;

    if (updatedTags.length == 1) {
      updatedTags[0].showTagRemoveIcon = false;
    } else {
      updatedTags.map(function(tag) {
        tag.showTagRemoveIcon = true;
      });
    }

    return updatedTags;
  }

  handleGeneratedTags = (rawTags) => {
    var tags = [];

    tags = rawTags.map(function(rawTag) {
      return { key: guid(), tag: rawTag };
    });

    if (tags.length < 1) {
      tags = [{key: guid()}];
    }

    tags = this.tagsWithUpdatedRemoveIconStatus(tags);
    this.setState({tags: tags}, this.checkTagsLength);
  }

  handleSubmit = (event) => {
    this.setState({submitDisabled: true});
  }

  checkTagsLength = () => {
    if (this.state.tags.length > this.props.maxTagsCount) {
      this.setState({tooManyTags: true, submitDisabled: true });
    } else {
      this.setState({tooManyTags: false, submitDisabled: false});
    }
  }
};

export default MultiTagCreateForm;
