export class KeyCode {
  static BACKSPACE = 'Backspace';
  static TAB = 'Tab';
  static ENTER = 'Enter';
  static ESCAPE = 'Escape';
  static SPACE = ' ';
  static LEFT = 'ArrowLeft';
  static UP = 'ArrowUp';
  static RIGHT = 'ArrowRight';
  static DOWN = 'ArrowDown';
  static DELETE = 'Delete';

  static A = 'A';
  static C = 'C';
  static V = 'V';
  static D = 'D';
  static Z = 'Z';
  static Y = 'Y';

  static F2 = 'F2';

  static PAGE_UP = 'PageUp';
  static PAGE_DOWN = 'PageDown';
  static PAGE_HOME = 'Home';
  static PAGE_END = 'End';
}

export const onKeyDownTextNavigation = (event) => {
  const key = event.key;

  const isNavigationKey = key === KeyCode.KEY_LEFT ||
    key === KeyCode.KEY_RIGHT ||
    key === KeyCode.KEY_UP ||
    key === KeyCode.KEY_DOWN ||
    key === KeyCode.KEY_PAGE_DOWN ||
    key === KeyCode.KEY_PAGE_UP ||
    key === KeyCode.KEY_PAGE_HOME ||
    key === KeyCode.KEY_PAGE_END ||
    key === KeyCode.ENTER;

  if (isNavigationKey) {
    // this stops the grid from receiving the event and executing keyboard navigation
    event.stopPropagation();
  }
}