import React, { memo } from 'react';

export default memo(props => {
  const style = {
    color: '#181d1f'
  }

  return (
    <div className="grid-header" style={style}>
      <div className='display-block column-name'>
        {props.displayName}
        {props.required &&
          <span className="text-danger"> *</span>
        }
      </div>
      <div className='display-block text-muted text-regular mt-5'>
        <span className='text-size-mini column-type' >{props.typeName}</span>
      </div>
    </div>
  );
});