import React from 'react';
import PropTypes from 'prop-types';

export default function TextCounter({ maxCount, currentValue, counterClassNames = 'text-muted' }) {
  const renderNode = maxCount > 0;

  const getNode = () => {
    const currentCount = currentValue ? currentValue.length : 0
    const remainingCount = maxCount - currentCount;

    let classes = '';
    if (remainingCount < 1) {
      classes += " text-danger"
    }

    return <span className={counterClassNames + classes}>{remainingCount}</span>
  }

  return (
    <>
      {renderNode && getNode()}
    </>
  )
}

TextCounter.propTypes = {
  maxCount: PropTypes.number,
  currentValue: PropTypes.string
}