// https://codesandbox.io/s/zqwz5n5p9x Use this to make it cleaner
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import FieldsColumn from './pfFieldsSelector/FieldsColumn.jsx';
import Checkbox from './shared/Checkbox';

export default function PfFieldsSelector({ allFieldsHash = {}, allFieldIds, initialFieldsIds, initialAllowCrossDependency, showFieldEditLink }) {
  const [currentFieldIds, setCurrentFieldsIds] = useState(initialFieldsIds);
  const [allowCrossDependency, setAllowCrossDependency] = useState(initialAllowCrossDependency);

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    // There was no drop
    if (!destination) {
      return;
    }

    // Dropped at the same place
    if (destination.droppableId === source.droppableId
      && destination.index === source.index) {
      return;
    }

    // Moving within the same list
    if (source.droppableId === destination.droppableId) {
      // Ignore if it's the available fields column
      if (source.droppableId === 'available-fields-column') {
        return;
      }

      // Moving within current fields
      const newFieldIds = Array.from(currentFieldIds);
      newFieldIds.splice(source.index, 1);
      newFieldIds.splice(destination.index, 0, draggableId);

      setCurrentFieldsIds(newFieldIds);
      return;
    }

    // Adding to the current fields
    if (destination.droppableId === 'current-fields-column') {
      const newFieldIds = Array.from(currentFieldIds);
      newFieldIds.splice(destination.index, 0, draggableId);
      setCurrentFieldsIds(newFieldIds);
      return;
    }

    // Removing from the current fields
    if (source.droppableId === 'current-fields-column') {
      const newFieldIds = Array.from(currentFieldIds);
      newFieldIds.splice(source.index, 1);
      setCurrentFieldsIds(newFieldIds);
    }
  };

  const availableFieldsIds = () => {
    const result = allFieldIds.filter((id) => !currentFieldIds.includes(id));
    return result;
  };

  const fields = (fieldIds) => {
    // IMPORTANT: Return the result in fieldIds order
    const result = fieldIds.map((id) => allFieldsHash[id]);
    return result;
  };

  // Perhaps use useMemo to avoid calculation everytime
  const missingFields = () => {
    if (allowCrossDependency) {
      return [];
    }

    let missingFieldPairs = []
    const currentFields = fields(currentFieldIds);
    for (const field of currentFields) {
      if (field.parent_field_id && !currentFieldIds.includes(field.parent_field_id)) {
        const missingField = fields([field.parent_field_id])[0];
        missingFieldPairs.push({ addedField: field, missingField: missingField });
      }
    }

    return missingFieldPairs;
  }

  const missingFieldsError = () => {
    const fieldPairs = missingFields();
    if (fieldPairs.length > 0) {
      const missingFieldDetails = fieldPairs.map(data => {
        return (
          <li key={data.addedField.id}>
            <span className='text-semibold'> {data.missingField.name} </span>
            should be added for the dependent field
            <span className='text-semibold'> {data.addedField.name} </span>
            to work correctly.
          </li>
        )
      })
      return (
        <div className="alert alert-danger">
          <span className="text-semibold">
            Found missing fields (highlighted below):
          </span>

          <ul className="text-danger">
            {missingFieldDetails}
          </ul>
        </div>
      )
    }
  };

  const submitButton = () => {
    const disabled = currentFieldIds.length === 0 || missingFields().length > 0;

    return (
      <div className="heading-elements">
        <button type="submit" className="btn btn-primary btn-xs heading-btn" disabled={disabled}>Save</button>
      </div>
    )
  }

  const errorFieldIds = () => {
    return missingFields().map(fieldPair => fieldPair.missingField.id);
  }

  const handleChangeAllowCrossDependency = () => {
    setAllowCrossDependency(!allowCrossDependency)
  }

  return (
    <div style={{ height: '200vh' }}>
      {missingFieldsError()}
      <div className='form-group'>
        <Checkbox
          id='allow-cross-dependency'
          label="Allow fields to be dependent across different parameters (NOTE: If this is checked, also enable the Field Mode of the related convention)"
          value={allowCrossDependency}
          name='parameter_format[allow_cross_dependency]'
          onChange={handleChangeAllowCrossDependency}
        />
      </div>
      <div className="sticky">
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="available-fields col-md-6 mt-5">
            <FieldsColumn
              columnId='available-fields-column'
              title="Available Fields"
              subtitle='In alphabetical order'
              fields={fields(availableFieldsIds())}
              errorFieldIds={errorFieldIds()}
              styleType='default'
              showFieldEditLink={showFieldEditLink}
            />
          </div>
          <div className="current-fields col-md-6 mt-5">
            <FieldsColumn
              columnId='current-fields-column'
              title="Configured Fields"
              subtitle='In desired order'
              fields={fields(currentFieldIds)}
              styleType='success'
              populateInputs={true}
              submitButton={submitButton()}
              showFieldEditLink={showFieldEditLink}
            />
          </div>
        </DragDropContext>
      </div>
    </div>
  );
}

PfFieldsSelector.propTypes = {
  allFieldsHash: PropTypes.any.isRequired,
  allFieldIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialFieldsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  showFieldEditLink: PropTypes.bool.isRequired
};
