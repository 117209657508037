import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle
} from "react";
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import TextInputValidator from "../conventionFields/TextInputValidator";

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="caret"></i>
    </components.DropdownIndicator>
  );
};

const ClearIndicator = props => {
  return (
    <components.ClearIndicator {...props}>
      <i className="icon-cross3"></i>
    </components.ClearIndicator>
  );
};

const optionElement = (props) => {
  return (
    <>
      <span>{props.label}</span>
      <span className='text-muted pl-5'>{props.data.description}</span>
    </>
  )
};

const SingleOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        {optionElement(props)}
      </components.Option>
    </div>
  );
};

// Show checkboxes for multiple select
const MultiOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="checkbox no-margin">
          <label className="pl-20">
            <input
              className='no-margin'
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            />{" "}
            {optionElement(props)}
          </label>
        </div>
      </components.Option>
    </div>
  );
};

export default forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);
  const [options, setOptions] = useState(props.options)
  const [editing, setEditing] = useState(true);
  const refInput = useRef(null);
  const textValidator = new TextInputValidator(props.textLimit, props.allowedCharacters, props.prohibitedCharacters);

  // TODO: Set a minimum width so that it's not too small.
  const width = props.column.getActualWidth() + 'px';
  const height = props.rowHeight + 'px';

  const customStyles = {
    container: provided => ({
      ...provided,
      width: width,
    }),
    control: provided => ({
      ...provided,
      width: width,
      minHeight: height,
      borderRadius: '0px',
      border: 'none',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      padding: '2px',
    }),
    clearIndicator: provided => ({
      ...provided,
      padding: '2px',
    }),
    indicatorsContainer: provided => ({
      ...provided,
      paddingRight: '11px'
    })
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value;
      },

      // afterGuiAttached: () => {
      //   setValue(props.value)
      // },
    };
  });

  useEffect(() => {
    setTimeout(() => refInput.current.focus());
  }, []);

  // TODO: This is common with other editors like TextCellEditor.jsx
  useEffect(() => {
    if (!editing) {
      props.api.stopEditing();
    }
  }, [editing]);

  const onChangeHandler = (newValue) => {
    setValue(newValue);
    // Let editing continue if it is multi select
    if (!props.multipleSelect) {
      setEditing(false);
    }
  }

  // OnChange handler is not called when onCreateOption is called
  // const handleCreate = (inputString) => {
  //   const cleanedString = textValidator.validate(inputString);

  //   if (Boolean(cleanedString)) {
  //     const newValue = { value: cleanedString, label: cleanedString };

  //     setOptions([...options, newValue]);
  //     if (props.multipleSelect) {
  //       setValue([...value, newValue]);
  //     } else {
  //       setValue(newValue);
  //     }
  //   }
  // }

  const isValidNewOption = (inputString) => {
    return Boolean(inputString) && textValidator.isValid(inputString);
  }

  const onBlurHandler = (e) => {
    setEditing(false);
  }

  let selectComponents = {
    DropdownIndicator: DropdownIndicator,
    ClearIndicator: ClearIndicator,
    Option: props.multipleSelect ? MultiOption : SingleOption
  }

  const selectOptions = {
    // menuIsOpen: true, // DEV: Set to true when debugging to keep it open, etc.
    ref: refInput,
    styles: customStyles,
    components: selectComponents,
    options: options,
    defaultValue: value,
    value: value,
    defaultMenuIsOpen: true,
    name: "",
    onChange: onChangeHandler,
    // onCreateOption: handleCreate,
    isValidNewOption: isValidNewOption,
    onBlur: onBlurHandler,
    isSearchable: true,
    isClearable: true,
    isMulti: props.multipleSelect,
    allowSelectAll: props.multipleSelect,
    hideSelectedOptions: false,
    closeMenuOnSelect: !props.multipleSelect,
    className: 'dropdown-select-cell-container',
    classNamePrefix: 'dropdown-select-cell'
  }

  return (
    <>
      {
        props.allowCreate
          ? <CreatableSelect {...selectOptions} />
          : <Select {...selectOptions} />
      }
    </>
  )

})