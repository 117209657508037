// Field helper functions
export const valueWithPrefixSuffix = (value, prefix, suffix) => {
  return (prefix || "") + (value || "") + (suffix || "");
}

export const valueWithoutPrefixSuffix = (value, prefix, suffix) => {
  let prefixPattern = "^(" + prefix + ")";
  let prefixRegex = new RegExp(prefixPattern)
  let withoutPrefix = value.replace(prefixRegex, "");

  let suffixPattern = "(" + suffix + ")$";
  let suffixRegex = new RegExp(suffixPattern)
  let withoutPrefixSuffix = withoutPrefix.replace(suffixRegex, "");

  return withoutPrefixSuffix
}

export const areAllRequiredFieldsFilled = (fields) => {
  var filled = true;

  for (var i = 0, l = fields.length; i < l; i++) {
    var field = fields[i];
    if (field.required &&
      isBlank(field.current_value) &&
      (isBlank(field.multiple_values) || field.multiple_values.length == 0)) {
      filled = false;
      break;
    }
  }

  return filled;
}

export const calculatedFormatValue = (fields, separator) => {
  let current_values = [];
  let fieldsMap = []

  for (var i = 0, l = fields.length; i < l; i++) {
    var field = fields[i];
    let currentValue = (field.current_value || "").trim();

    if (!isBlank(currentValue)) {
      let finalValue = valueWithPrefixSuffix(currentValue, field.prefix, field.suffix)
      current_values.push(finalValue);
      fieldsMap.push({
        field_id: field.id,
        field_name: field.name,
        final_value: finalValue,
        input_value: currentValue,
        prefix: field.prefix,
        suffix: field.suffix
      });
    }
  }
  return { value: current_values.join(separator), fieldsMap: fieldsMap };
}

export const calculatedMultipleFormatValues = (fields, separator) => {
  let allValueCombinations = [];
  let newAllValueCombinations = []

  for (var i = 0, l = fields.length; i < l; i++) {
    var field = fields[i];
    if (!isBlank(field.multiple_values)) {
      newAllValueCombinations = []

      for (let newFieldValue of field.multiple_values) {
        if (!isBlank(newFieldValue)) {
          if (allValueCombinations.length > 0) {
            for (const value of allValueCombinations) {
              let newFieldValueWithPS = valueWithPrefixSuffix(newFieldValue, field.prefix, field.suffix)
              let newJoinedValue = [value, newFieldValueWithPS].join(separator);
              newAllValueCombinations.push(newJoinedValue);
            }
          } else {
            // If the multiple_values field is first in the format, it will seed the values
            newAllValueCombinations.push(newFieldValue);
          }
        }
      }

      allValueCombinations = newAllValueCombinations;
    } else {
      // Field current_value is not blank
      let finalValue = valueWithPrefixSuffix(field.current_value, field.prefix, field.suffix)
      allValueCombinations = appendNewJoinedValue(allValueCombinations, finalValue, separator);
    }
  }

  // Return unique values only
  return [...new Set(allValueCombinations)];
}

export const isBlank = (value) => {
  return (value === undefined || value === null || value === "");
}

export const findFieldForFieldId = (fieldId, fields) => {
  var field = fields.find(function (f) {
    return f.id === fieldId;
  });
  return field;
}

// Private functions
const appendNewJoinedValue = (allValueCombinations, newFieldValue, separator) => {
  if (allValueCombinations.length > 0) {
    for (const [index, value] of allValueCombinations.entries()) {
      let newJoinedValue = [value, newFieldValue].join(separator);
      allValueCombinations[index] = newJoinedValue;
    }
  } else {
    allValueCombinations.push(newFieldValue);
  }

  return allValueCombinations;
}
