import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove
} from 'react-sortable-hoc';

import guid from './shared/guid';
import Column from './customUrlColumns/Column';

const DragHandle = SortableHandle(() => {
  return (
    <div className="col-xs-1 col-drag-handle">
      <i className="icon-three-bars text-muted pt-10 cursor-move " title="Reorder"></i>
    </div>
  );
})

const SortableItem = SortableElement(({idx,
                                       column,
                                       baseInputName,
                                       availableColumns,
                                       handleNameChange,
                                       handleHeaderChange,
                                       onRemove,
                                       inputDisabled}) => {
  return (
    <div className="row mb-10">
      <DragHandle />
      <Column
        index={idx}
        column={column}
        baseInputName={baseInputName}
        availableColumns={availableColumns}
        handleNameChange={handleNameChange}
        handleHeaderChange={handleHeaderChange}
        onRemove={onRemove}
        inputDisabled={inputDisabled}
      />
    </div>
  )
})

const SortableList = SortableContainer(({columns,
                                         baseInputName,
                                         availableColumns,
                                         handleNameChange,
                                         handleHeaderChange,
                                         onRemove,
                                         inputDisabled}) => {
  return (
    <div>
      {columns.map((column, index) =>
        <SortableItem
          key={column.key}
          index={index}
          idx={index}
          column={column}
          baseInputName={baseInputName}
          availableColumns={availableColumns}
          handleNameChange={handleNameChange}
          handleHeaderChange={handleHeaderChange}
          onRemove={onRemove}
          inputDisabled={inputDisabled}
        />
      )}
    </div>
  );
})

class CustomUrlColumnFields extends React.Component {
  static propTypes = {
    initialCustomUrlColumns: PropTypes.array,
    availableColumns: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      availableColumns: this.processInitialColumns(this.props.availableColumns),
      customUrlColumns: this.processInitialColumns(this.props.initialCustomUrlColumns)
    }
  }

  processInitialColumns = (columns) => {
    let self = this;

    for (let column of columns) {
      column.id = self.columnId(column);
      column.key = column.id;
    }

    return columns;
  }

  columnId = (column) => {
    return column.columnType + "-" + column.name;
  }

  render () {
    let self = this;

    let urlColumnsNode;

    if (this.state.customUrlColumns.length < 1) {
      urlColumnsNode = <input
        type="hidden"
        name="project[custom_url_columns][0][column_type]"
        value="default"
      />
    } else {
      urlColumnsNode = <div>
        <div className="row mb-10">
          <div className="col-xs-1 col-drag-handle"></div>
          <div className="col-xs-5">
            <div className="text-semibold">Column Value</div>
          </div>
          <div className="col-xs-5">
            <div className="text-semibold">Column Heading</div>
          </div>
          <div className="col-xs-1"></div>
        </div>

        <SortableList
          columns={this.state.customUrlColumns}
          onSortEnd={this.onSortEnd}
          baseInputName={"project[custom_url_columns]"}
          availableColumns={this.state.availableColumns}
          handleNameChange={this.handleNameChange}
          handleHeaderChange={this.handleHeaderChange}
          onRemove={this.handleColumnRemove}
          inputDisabled={this.props.disabled}
          useDragHandle={true}
          lockAxis="y"
        />
      </div>
    }

    let addColumnNode;

    if (!this.props.disabled) {
      addColumnNode = (
        <div className="row">
          <div className="col-xs-1 col-drag-handle"></div>
          <div className="col-xs-11">
            <a onClick={this.handleAddColumn}>
              <i className="icon-plus22 position-left"></i>
              Add column
            </a>
          </div>
        </div>
      )
    }

    return (
      <div>
        <div className='form-group'>
          <div className="col-xs-12">
            {urlColumnsNode}
            {addColumnNode}
          </div>
        </div>
      </div>
    )
  }

  onSortEnd = ({oldIndex, newIndex}) => {
     this.setState({ customUrlColumns: arrayMove(this.state.customUrlColumns, oldIndex, newIndex)});
  }

  handleNameChange = (index, value) => {
    let validColumn = this.state.availableColumns.find(column => column.id === value);

    if (validColumn) {
      let newColumns = update(
        this.state.customUrlColumns, {
          [index]: {
            id:   {$set: validColumn.id},
            columnType: {$set: validColumn.columnType},
            name: {$set: validColumn.name},
            header: {$set: validColumn.header}
          }
        }
      );

      this.setState({ customUrlColumns: newColumns });
    }
  }

  handleHeaderChange = (index, value) => {
    var newColumns = update(this.state.customUrlColumns, { [index]: { header: {$set: value} }});
    this.setState({customUrlColumns: newColumns});
  }

  handleColumnRemove = (index) => {
    let newColumns = update(this.state.customUrlColumns, {$splice: [[index, 1]]})
    this.setState({ customUrlColumns: newColumns })
  }

  handleAddColumn = () => {
    let newColumns = update(
      this.state.customUrlColumns, {$push: [this.newColumn()]}
    )

    this.setState({ customUrlColumns: newColumns })
  }

  newColumn = () => {
    return { key: guid(), header: "", columnType: "", name: ""};
  }
};

export default CustomUrlColumnFields;
