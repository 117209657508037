import React, { useState } from 'react';
import FormatResult from '../conventionFields/FormatResult';

const FORMAT_CONTROLLED = 'format_controlled'

export default function FormData({ gridApi, columns }) {

  const rowDataNode = () => {
    let rowData = [];

    gridApi.forEachNode((rowNode) => {
      rowData.push(rowNode.data);
    });

    return <input type="hidden" name="grid_mode_builder[row_data]" value={JSON.stringify(rowData)} />
  }

  const pinnedRowDataNode = () => {
    const pinnedRowData = gridApi.getPinnedTopRow(0).data;
    return <input type="hidden" name="grid_mode_builder[pinned_row_data]" value={JSON.stringify(pinnedRowData)} />
  }

  const allInputNodes = () => {
    let nodes = [];
    gridApi.forEachNode(rowNode => (
      nodes.push(<div key={rowNode.id}>
        {inputNodes(rowNode)}
      </div>
      )
    ))
    return nodes;
  }

  const inputNodes = (rowNode) => {
    let nodes = []

    for (const column of columns) {
      if (column.input_name) {
        let valueObject = gridApi.getValue(column.field, rowNode)
        if (!valueObject) continue;

        let nodeItems = [];

        if (column.field.startsWith('utm_') || column.field.startsWith('custom_') || column.field.startsWith('info_')) {
          if (column.column_type === FORMAT_CONTROLLED) {
            nodeItems = [(
              // This requires result to have a property 'value' which valueObject has.
              <FormatResult
                key={column.input_name}
                inputName={column.input_name}
                formatId={valueObject.formatId}
                result={valueObject}
                hidden={true}
              />
            )]
          } else {
            nodeItems = [(
              <input key={column.input_name} type="hidden" name={`${column.input_name}[tag]`} value={valueObject.value} />
            )]
          }
        } else {
          // For labels
          if (Array.isArray(valueObject)) {
            nodeItems = valueObject.map(item => {
              return (
                <input key={item.value} type="hidden" name={column.input_name} value={item.value} />
              )
            });
          } else {
            nodeItems = [(
              <input key={column.input_name} type="hidden" name={column.input_name} value={valueObject.value} />
            )]
          }
        }

        nodes = nodes.concat(nodeItems);
      }
    }

    return nodes;
  }

  return (
    <div>
      {rowDataNode()}
      {pinnedRowDataNode()}
      {allInputNodes()}
    </div>
  );
};